import { render, staticRenderFns } from "./userassetsdetails.vue?vue&type=template&id=1d6162e2&scoped=true"
import script from "./userassetsdetails.vue?vue&type=script&lang=js"
export * from "./userassetsdetails.vue?vue&type=script&lang=js"
import style0 from "./userassetsdetails.vue?vue&type=style&index=0&id=1d6162e2&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d6162e2",
  null
  
)

export default component.exports