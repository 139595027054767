<template>
  <div class="pageBox">
    <!--  面包屑  -->
    <div
      class="mrj-layout-tabs-custom flex align-item-center"
      style="z-index:1;"
    >
      <div
        @click="changeRoute"
        class="mrj-layout-tabs-custom-index flex align-item-center cursor-pointer"
      >
        <div
          type="left"
          class="leftarrow"
          :class="imgflag?'leftarrow_hover':''"
          @mouseenter="imgflag=true"
          @mouseleave="imgflag=false"
        >
          <i class="meiye-icon meiye-fanghui"></i>
        </div>

        <span
          @mouseenter="imgflag=true"
          @mouseleave="imgflag=false"
        >{{ $route.meta.title }}</span>
      </div>
      <span class="mrj-layout-tabs-custom-fenge">/</span>
      <span class="mrj-layout-tabs-custom-inner-page">{{'已消耗用户资产详情' }}</span>
    </div>
    <div class="page-list personnelfiles flex-1 ">
      <!-- goodscards -->
      <div class="goodscards flex  ">
        <div class="goodscardsleft flex  ">
          <div class="goodscardsleft_img">
            <img
              src="https://sandbox.static.meirenji.vip/hly_unknow//2023/06/29/649d941af3444.png"
              alt=""
            >
          </div>
          <div class="goodscardsleft_txt">
            <p class="goodscardsleft_p1"><span class="font4">靓彩清润面膜</span><span class="font3">类型：{{$route.query.goods_type==1?'产品':$route.query.goods_type==2?'服务':$route.query.goods_type==3?'储值卡':'卡项'}}</span> </p>
            <p class="goodscardsleft_p2"><span class="font3">面值：</span> <span class="font3">¥2000.32</span> </p>
          </div>
        </div>
        <div
          class="goodscardsrgt flex align-item-center flex-1"
          v-if="$route.query.goods_type==1"
        >
          <div class="goodsitems">
            <div class="goodscardsrgt_top">寄存总数量</div>
            <div class="goodscardsrgt_btm">32</div>
          </div>
          <div class="goodsitems">
            <div class="goodscardsrgt_top">客户带走总数量</div>
            <div class="goodscardsrgt_btm"> 21</div>
          </div>
          <div class="goodsitems">
            <div class="goodscardsrgt_top">在店消耗总数量</div>
            <div class="goodscardsrgt_btm">21</div>
          </div>
          <div class="goodsitems">
            <div class="goodscardsrgt_top">不可用总数量</div>
            <div class="goodscardsrgt_btm">32</div>
          </div>
          <div class="goodsitems">
            <div class="goodscardsrgt_top">剩余寄存总数量</div>
            <div class="goodscardsrgt_btm">32</div>
          </div>

        </div>
        <div
          class="goodscardsrgt flex align-item-center flex-1"
          v-if="$route.query.goods_type==2"
        >
          <div class="goodsitems">
            <div class="goodscardsrgt_top">购买总数量</div>
            <div class="goodscardsrgt_btm">32</div>
          </div>
          <div class="goodsitems">
            <div class="goodscardsrgt_top">商城累计支付金额</div>
            <div class="goodscardsrgt_btm"> ￥5621</div>
          </div>
          <div class="goodsitems">
            <div class="goodscardsrgt_top">已消耗总数量</div>
            <div class="goodscardsrgt_btm">21</div>
          </div>
          <div class="goodsitems">
            <div class="goodscardsrgt_top">不可用总数量</div>
            <div class="goodscardsrgt_btm">32</div>
          </div>
          <div class="goodsitems">
            <div class="goodscardsrgt_top">剩余寄存总数量</div>
            <div class="goodscardsrgt_btm">32</div>
          </div>

        </div>
        <div
          class="goodscardsrgt flex align-item-center flex-1"
          v-if="$route.query.goods_type==3"
        >
          <div class="goodsitems goodsitems3">
            <div class="goodscardsrgt_top">购买总数量</div>
            <div class="goodscardsrgt_btm">32</div>
          </div>
          <div class="goodsitems goodsitems3">
            <div class="goodscardsrgt_top">商城累计支付金额</div>
            <div class="goodscardsrgt_btm">¥3,332.21</div>
          </div>
          <div class="goodsitems goodsitems3">
            <div class="goodscardsrgt_top">已失效总数量</div>
            <div class="goodscardsrgt_btm">21</div>
          </div>
          <div class="goodsitems goodsitems3">
            <div class="goodscardsrgt_top">使用中总数量</div>
            <div class="goodscardsrgt_btm">32</div>
          </div>

        </div>
        <div
          class="goodscardsrgt flex align-item-center flex-1"
          v-if="$route.query.goods_type==4"
        >
          <div class="goodsitems">
            <div class="goodscardsrgt_top">购买总数量</div>
            <div class="goodscardsrgt_btm">32</div>
          </div>
          <div class="goodsitems">
            <div class="goodscardsrgt_top">商城累计支付金额</div>
            <div class="goodscardsrgt_btm">¥3,332.21</div>
          </div>
          <div class="goodsitems">
            <div class="goodscardsrgt_top">使用中总数量</div>
            <div class="goodscardsrgt_btm">21</div>
          </div>
          <div class="goodsitems">
            <div class="goodscardsrgt_top">待激活总数量</div>
            <div class="goodscardsrgt_btm">32</div>
          </div>
          <div class="goodsitems">
            <div class="goodscardsrgt_top">已停卡总数量</div>
            <div class="goodscardsrgt_btm">32</div>
          </div>
          <div class="goodsitems">
            <div class="goodscardsrgt_top">已失效总数量</div>
            <div class="goodscardsrgt_btm">12</div>
          </div>
        </div>
      </div>

      <a-row class="filter-wrap ">
        <div class="fillter_flx flex ">
          <!-- 复合搜索框 -->
          <div class="filterbox flex">
            <ComposeInput
              :selectArr="selectArr"
              placeholderValue="请输入用户昵称"
              @getList="getListSearch"
            ></ComposeInput>
            <!-- 全部门店 -->
            <a-select
              class="mgl-16  wid-180"
              default-value="门店录入"
              placeholder="门店"
              @change="handleChange"
            >
              <a-select-option value="0">
                全部
              </a-select-option>
              <a-select-option value="1">
                门店录入
              </a-select-option>
              <a-select-option value="2">
                直播间
              </a-select-option>
              <a-select-option value="3">
                合伙人小程序
              </a-select-option>
              <a-select-option value="4">
                活动一
              </a-select-option>
            </a-select>
            <!-- 日期选择器 -->
            <a-range-picker
              @change="setMonth"
              class="data_picker  mgl-16  wid-248"
              v-if="$route.query.goods_type==1 || $route.query.goods_type==2"
            >
              <i
                slot="suffixIcon"
                class="meiye-icon meiye-rili-moren"
                :class="{ 't-c-g': isactive }"
                style="font-size:12px;color:#9ea6a4 ;background: @baseColor41;margin-top: -5px;"
              ></i>
            </a-range-picker>

          </div>

        </div>
      </a-row>
      <div
        class="cardsbox"
        v-if='$route.query.goods_type==4'
      >
        <div class="cardsone">
          <p class="cardsone_p1">待激活总数量</p>
          <p class="cardsone_p2">12</p>
        </div>
        <div class="cardsone">
          <p class="cardsone_p1">已停卡总数量2</p>
          <p class="cardsone_p2">40</p>
        </div>
        <div class="cardsone">
          <p class="cardsone_p1">使用中总数量</p>
          <p class="cardsone_p2">14</p>
        </div>
      </div>
      <div
        class="cardsbox"
        v-if='$route.query.goods_type==3 || $route.query.goods_type==2'
      >
        <div class="cardsone">
          <p class="cardsone_p1">已消耗总数量</p>
          <p class="cardsone_p2">12</p>
        </div>
        <div class="cardsone">
          <p class="cardsone_p1">剩余可消耗总数量</p>
          <p class="cardsone_p2">40</p>
        </div>
      </div>
      <div
        class="cardsbox"
        v-if='$route.query.goods_type==1'
      >
        <div class="cardsone">
          <p class="cardsone_p1">客户带走总数量</p>
          <p class="cardsone_p2">12</p>
        </div>
        <div class="cardsone">
          <p class="cardsone_p1">在店消耗总数量</p>
          <p class="cardsone_p2">40</p>
        </div>
        <div class="cardsone">
          <p class="cardsone_p1">剩余寄存总数量</p>
          <p class="cardsone_p2">40</p>
        </div>
      </div>
      <a-table
        class="popTable3  flex-1"
        @change="tableChanged"
        :pagination="table.pagination"
        :rowKey="(record,index)=>index"
        :loading="table.loading"
        :columns="config.userassets.columns_details"
        :data-source="table.tableData"
        :scroll="{ y:getfullHeight( 356)  }"
        :rowClassName="setRowClass"
        :customRow="customRow"
      >
        <template
          slot="_1"
          slot-scope="action, record"
        >
          <span class="pickupname mgr-4">{{ record.permission_name || '张某某' }}</span>
          <span class="pickupphome">{{ record.phome || '13874745816' }}</span>
        </template>

        <template
          slot="_7"
          slot-scope="action, record"
        >
          <a-button
            size="small"
            class="mgr-12 smallbtn"
            @click="addRules( record,3)"
          >查看</a-button>
        </template>
      </a-table>
    </div>

    <div
      class=" flex-1"
      v-if="pageStatus=='add'"
    >
      <!-- <management-add></management-add> -->
    </div>

    <!--   查看  -->
    <a-modal
      :visible="association.visible"
      width="980px"
      :title="association.title"
      wrapClassName="commonModel2"
      @cancel="association.visible=false"
      :footer="null"
      destroyOnClose
    >

      <div class="consTable">
        <a-table
          class="popTable3"
          :pagination="false"
          :rowKey="record=>record.category_id"
          :loading="association.loading"
          :columns="association.config"
          :scroll="{  y: 448  }"
          :data-source="association.tableData"
        ></a-table>

      </div>
      <template slot="title">
        <div style="margin: 3px 0;">
          <span
            class="titmodel"
            style="margin-right:8px;"
          >{{ '取货明细' }}</span>

        </div>
      </template>
    </a-modal>

  </div>

</template>
<script> 
import storage from 'store'
import moment from 'moment';
import Msg from '@/components/mrj-menu/msg'
import {
  GetmanageGoodslist,
  GetgoodsoptionsList, //门店分类
  TreetypeFinanceList,//财务分类
  TreetypeMallList,//商城分类
  CreateGoods, UpdateGoods, GoodsInfo, GetgoodsbrandsoptionsList, DeLeteGoods, BatchGoods
} from '@/api/commodity'
import config from '../../config'
import axios from 'axios'
import UploadImage from '@/views/commodity/modules/drag'
import ShopEditor from '@/views/commodity/modules/wangeditor'
// import ShopDetail from './mallshopdetails'
// import ServiceDetail from './mallshopservicedetails'
// import PlanDetail from './mallshopplandetails'
// import CardDetail from './mallshopcarddetails'
// import ManagementGrounding from '../../commodity/management/management_grounding'
// import ManagementGroundingAll from '../../commodity/management/management_grounding_all'
import ComposeInput from '@/components/compose_input'
import difference from 'lodash/difference';
// import ManagementAdd from './modules/malluserdetails'
// import { TableFilter } from 'huanlong-components'
// import  TableFilters  from '@/components/intimate/tableFilter'

const leftTableColumns = [
  {
    dataIndex: 'title',
    title: 'title',
  },
  {
    dataIndex: 'component',
    title: 'component',
  },
];
const rightTableColumns = [
  {
    dataIndex: 'title',
    title: 'title',
  },
  {
    dataIndex: 'component',
    title: 'component',
  },
];
const treeDatas = [
  { key: '0-0', title: '0-0' },
  {
    key: '0-1',
    title: '0-1',
    children: [
      { key: '0-1-0', title: '0-1-0' },
      { key: '0-1-1', title: '0-1-1' },
    ],
  },
  { key: '0-2', title: '0-3' },
]
const transferDataSource = [];
function flatten(list = []) {
  list.forEach(item => {
    transferDataSource.push(
      // {
      // title: item.title,
      // key: item.id.toString(),
      // value: item.path ? item.path : 'xxx'
      item
      // }
    );
    flatten(item.children);
    console.log(transferDataSource, 'typesModel.dataSource');
  });
}
flatten(JSON.parse(JSON.stringify(treeDatas)));
function isChecked(selectedKeys, eventKey) {
  return selectedKeys.indexOf(eventKey) !== -1;
}

function handleTreeData(data, targetKeys = []) {
  data.forEach(item => {
    item['disabled'] = targetKeys.includes(item.key);
    if (item.children) {
      handleTreeData(item.children, targetKeys);
    }
  });
  return data;
}

export default {
  inject: ['reload'],
  components: {
    UploadImage, ShopEditor, ComposeInput,
    // ManagementAdd
    //  ShopDetail, ServiceDetail, PlanDetail, CardDetail
    // , TableFilters
  },
  data() {
    return {
      pageStatus: 'list',//groundingAll  add  grounding 
      config,
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      headers: {
        authorization: storage.get('token'),
        "Content-Type": "multipart/form-data",
      },
      imgflag: false,
      selectArr: [
        { name: '用户昵称', id: '1', key: 1, placeholder: '请输入用户昵称' },
        { name: '用户姓名', id: '2', key: 2, placeholder: '请输入用户姓名' },
        { name: '手机号', id: '3', key: 3, placeholder: '请输入手机号' },
      ],
      selectArr2: [
        { name: '财务分类', id: '1', key: 1 },
        { name: '门店分类', id: '2', key: 2 },
        { name: '商城分类', id: '3', key: 3 },
      ],
      selectArr3: [
        { name: '机构简称', id: '1', key: 1 },
        { name: '机构名称', id: '2', key: 2 },
        { name: '机构代码', id: '3', key: 3 },
      ],
      statusvisible: false,
      typestyles: {
        width: '334px',
        height: "32px"
      },
      typestyles2: {
        width: '506px',
        height: "32px"
      },
      isactive: false,
      activeStatus1: '1',//商品名称 商品码
      activeStatus2: '1',//批处理
      filters_categorys: [//商品分类
        { text: '全部', value: '0', key: 0 },
        { text: '扫码核销', value: '1', key: 1 },
        { text: '开单消耗', value: '2', key: 2 }
      ],
      activeStatus_catagory: [],
      filters_status: [
        { text: '全部', value: '0', key: 0 },
        { text: '消耗中', value: '1', key: 1 },
        { text: '已完成', value: '2', key: 2 },
        { text: '已取消', value: '3', key: 3 },
        { text: '已作废', value: '4', key: 4 },
      ],
      activeStatus_status: [],
      activeStatus_brand: [],

      activeStatus_types: [],
      ugro_id: '',
      query: {
        goods_name: undefined,//商品名称
        goods_bn: undefined,//商品码
        goods_type: [1, 2, 3, 4], //1客装 2院装 不传全部
        is_show: undefined, //1在售 0停售 不传为全部
        goods_brand: undefined, //品牌id 不传为全部
        goods_category: undefined, //门店分类 不传为全部
        store_category_id: undefined,// 商城分类
        finance_category_id: undefined,//财务分类
        start_data: undefined,//开始时间
        end_data: undefined,//结束时间
        limit: 10,
        page: 1,
      },
      table: {
        tableData: [
          {
            shop_name: '汉正街门店一号',
            id: 1,
            permission_name: '张xx',
            phone: '15874741698',
            num: 123,
            price: "￥56122.00",
            permission_type: 12,
            shop: 234
          },
          {
            shop_name: '汉正街门店二号',
            id: 2,
            phone: '15874741698',
            permission_name: '张xx',
            num: 123,
            price: "￥56122.00",
            permission_type: 12,
            shop: 234
          },
          {
            shop_name: '汉正街门店三号',
            id: 3,
            permission_name: '张xx',
            phone: '15874741698',
            num: 123,
            price: "￥56122.00",
            permission_type: 12,
            shop: 234
          },
        ],
        selectedRowKeys: [],
        selectedRows: [],
        loading: false,
        pagination: {
          current: 1,
          total: 0,
          pageSize: 10, //每页中显示10条数据
          showSizeChanger: true,
          showQuickJumper: true,
        },
      },

      pulsshow: true,
      permissionInfo: {
        visible: false,
        text: '提交',
        title: '',
        type: 1,//1新增   3编辑
        pic_url_loading: false,
        isDisabled: false,
        info: {
          permission_id_list: [],
          goods_type: ['shop', 'third_shop', 'third_guest', 'third_shop'],//商品类型
          goods_category: undefined,//商品分类
          goods_brand: undefined,//商品品牌
          goods_bn: undefined,
          goods_id: undefined,
          goods_name: undefined,
          goods_supplier: undefined,
          goods_unit: undefined,// 单位
          is_show: 1,// 商品状态
          is_del: 0,//是否删除
          goods_image: undefined,
          goods_slider: undefined,//轮播图
          goods_attr: [],//商品规格
          goods_attr_val: [],//商品规格值 
          goods_content: '<h1>q<strong>wqeddd</strong><span style=\"background-color: rgb(196, 29, 127);\">dxxxxx</span></h1><p>撒打 撒啊大</p>',//商品描述
          goods_desc: undefined,//商品简介
        }
      },
      permission_id_list: [],
      permission_id_list2: [],
      permission_id_list3: [],
      brandsList: [],
      treeData: [],
      treeData1: [],
      treeData2: [],
      treeData3: [],
      selectedKeys: [],
      selectedKeys2: [],
      selectedKeys3: [],
      expandedKeys: [],
      autoExpandParent: false,
      backupsExpandedKeys: [],
      titleList: [],//分类筛选名字 //财务  
      searchValue: '',
      selectreeObj: {
        children: 'children',
        title: 'category_name',
        key: 'category_id',
        value: 'category_id'
      },
      selectreeObj2: {
        children: 'children',
        title: 'category_name',
        key: 'finance_category_id',
        value: 'finance_category_id'
      },
      selectreeObj3: {
        children: 'children',
        title: 'category_name',
        key: 'store_category_id',
        value: 'store_category_id'
      },



      addorupdateSpecFlag: 'add',//是修改规格还是编辑 编辑的时候只能添加规格值
      // 权限弹框
      limitsModel: {
        visible: false,
        originalmockData: undefined,
        mockData: [],
        targetKeys: ['1', '3', '16', '30'],
        leftColumns: leftTableColumns,
        rightColumns: rightTableColumns,
        limitsreplaceFields: {
          key: "id",
          title: 'title'
        }
      },
      typesModel: {
        visible: false,
        originalmockData: undefined,
        mockData: [],
        targetKeys: [],
        leftColumns: leftTableColumns,
        rightColumns: rightTableColumns,
        dataSource: transferDataSource,
      },

      opentimes: {
        visible: false,
        data: {
          goods_id: '',
          goods_shou_type: 1,//1 长期 （默认） 2截止
          goods_shou_end_time: ''
        },
      },
      exportsobj: { //导出
        visible: false,
      },
      importsobj: {//导入
        visible: false,
      },
      visibleTime: false,
      // 
      flitList: [
        { key: '消耗方式', value: [], paramas: 'goods_type' },
        { key: ' ', value: [], paramas: 'goods_category' },
        { key: '消耗单状态', value: [], paramas: 'is_show' },
        { key: '品牌', value: [], paramas: 'goods_brand' },
        { key: '开单时间', value: [], paramas: 'data_time' },
        { key: '开单时间', value: [], paramas: 'data_time' },
      ],
      visiblePlan: false,
      isShowAll: true,
      isAllLength: '',
      CheckSureObj: {
        checkedKeys: undefined,
        info: undefined,
        tabs: 1,//财务1 商城3 门店2
      },
      shoptype: 1,//商品类型： 1客装实物,2店装实物，3合作客装实物，4合作店装实物，5服务，6合作服务，7储值卡，8方案 ，9合作方案
      // 备注弹框
      memo: {
        memovisible: false,
        content: undefined, // 备注内容
        images: [], // 上传照片 //goods_slider
        videos: undefined, // 上传视频
        datasource: [//备注列表
          {
            optime: '2023.06.23',
            operator: '张富贵',
            id: 1,
            memo: '顾客不想要套餐原因是效果不好顾客不想要套餐原因是效果不好，这里最多可以展示100字，展示不全用…顾客不想要套餐原因是效果不好顾客不想要套餐原因是效果不好，这里最多可以展示100字，展示不全用…顾客不想要套餐原因是效果不好顾客不想要套餐原因是效果不好，这里最多可以展示100字，展示不全用….',
            medai: ['https://sandbox.static.meirenji.vip/hly_unknow//2023/06/29/649d941af3444.png', 'https://sandbox.static.meirenji.vip/hly_unknow//2023/06/29/649d943370fdd.mp4']
          },
          {
            id: 2,
            optime: '2023.06.24',
            operator: '李富贵',
            memo: '顾客不想要套餐原因是效果不好顾客不想要套餐原因是效果不好，这里最多可以展示100字，展示不全用…顾客不想要套餐原因是效果不好顾客不想要套餐原因是效果不好，这里最多可以展示100字，展示不全用…顾客不想要套餐原因是效果不好顾客不想要套餐原因是效果不好，这里最多可以展示100字，展示不全用….',
            medai: ['https://sandbox.static.meirenji.vip/hly_unknow//2023/06/29/649d941af3444.png', 'https://sandbox.static.meirenji.vip/hly_unknow//2023/06/29/649d943370fdd.mp4']
          },

        ],
        columns: [
          {
            title: '操作时间',
            dataIndex: 'optime',
            key: '_0',
            ellipsis: true,
            scopedSlots: { customRender: '_0' },
          },
          {
            title: '操作人',
            dataIndex: 'operator',
            key: '_1',
            ellipsis: true,
            scopedSlots: { customRender: '_1' },
          },
          {
            title: '备注内容',
            dataIndex: 'memo',
            key: '_2',
            width: 386,
            scopedSlots: { customRender: '_2' },
          },
          {
            title: '资料图片',
            dataIndex: 'medai',
            key: '_3',
            ellipsis: true,
            scopedSlots: { customRender: '_3' },
          },
        ],
        url_loading: false,
        url_loading2: false,
      },
      //取货明细 
      association: {
        visible: false,
        title: '查看详情',
        obj: {
          name: 'www',
          num: '12',
        },
        loading: false,
        pagination: {
          current: 1,
          total: 1,
          pageSize: 8, //每页中显示10条数据
          showSizeChanger: true,
          showQuickJumper: false,
        },
        tableData: [
        ],
        config: [
          {
            title: '储值卡名称', // 服务名称 方案名称  
            dataIndex: 'goods_name',
            key: '_1',
            ellipsis: true,
            scopedSlots: { customRender: '_1' },
          },
          {
            title: '面值',
            dataIndex: 'goods_bn',
            key: '_2',
            ellipsis: true,
            scopedSlots: { customRender: '_2' },
          },
          {
            title: '实际支付',
            dataIndex: 'goods_num',
            key: '_3',
            ellipsis: true,
            scopedSlots: { customRender: '_3' },
          },
          {
            title: '现金率',
            dataIndex: 'is_give',
            key: '_4',
            ellipsis: true,
            scopedSlots: { customRender: '_4' },
          },
          {
            title: '余额',
            dataIndex: 'is_give',
            key: '_5',
            ellipsis: true,
            scopedSlots: { customRender: '_5' },
          },
          {
            title: '购买时间',
            dataIndex: 'is_give',
            key: '_6',
            ellipsis: true,
            scopedSlots: { customRender: '_6' },
          },
          {
            title: '到期时间',
            dataIndex: 'is_give',
            key: '_7',
            ellipsis: true,
            scopedSlots: { customRender: '_7' },
          },
          {
            title: '状态',
            dataIndex: 'is_give',
            key: '_8',
            ellipsis: true,
            scopedSlots: { customRender: '_8' },
          },
        ],
        config2: [
          {
            title: '卡项名称', //服务名称 方案名称 卡项名称
            dataIndex: 'goods_name',
            key: '_1',
            ellipsis: true,
            scopedSlots: { customRender: '_1' },
          },
          {
            title: '实际支付',
            dataIndex: 'goods_bn',
            key: '_2',
            ellipsis: true,
            scopedSlots: { customRender: '_2' },
          },
          {
            title: '已使用次数',
            dataIndex: 'goods_num',
            key: '_3',
            ellipsis: true,
            scopedSlots: { customRender: '_3' },
          },
          {
            title: '已消耗权益/项',
            dataIndex: 'is_give',
            key: '_4',
            ellipsis: true,
            scopedSlots: { customRender: '_4' },
          },
          {
            title: '剩余使用次数',
            dataIndex: 'is_give',
            key: '_5',
            ellipsis: true,
            scopedSlots: { customRender: '_5' },
          },
          {
            title: '到期时间',
            dataIndex: 'is_give',
            key: '_6',
            ellipsis: true,
            scopedSlots: { customRender: '_6' },
          },
          {
            title: '状态',
            dataIndex: 'is_give',
            key: '_7',
            ellipsis: true,
            scopedSlots: { customRender: '_7' },
          },
        ],
        flag: '1',// 

      },
      btnlists: {
        value: ['1'],
        btnlist: [
          { text: '编辑', value: '0', key: 0 },
          { text: '完成', value: '1', key: 1 },
          { text: '取消', value: '2', key: 2 },
          { text: '备注', value: '3', key: 3 },
        ],
      },
      isdatatimes: 1


    }
  },
  computed: {
    treeDatas() {
      return handleTreeData(treeDatas, this.typesModel.targetKeys);
    },
  },
  async created() {
    if (this.$route.query.goods_type == 1) {
      this.config.userassets.columns_details = this.config.userassets.columns_details_1
    }
    if (this.$route.query.goods_type == 2) {
      this.config.userassets.columns_details = this.config.userassets.columns_details_2
    }
    if (this.$route.query.goods_type == 3) {
      this.config.userassets.columns_details = this.config.userassets.columns_details_3
    }
    if (this.$route.query.goods_type == 4) {
      this.config.userassets.columns_details = this.config.userassets.columns_details_4
    }
  },
  mounted() {

  },
  methods: {
    flatten(list = []) {
      let _this = this
      if (list) {
        list.forEach(item => {
          _this.limitsModel.mockData.push({
            key: item.id.toString(),
            title: item.title ? item.title : 'we' + item.id,
            component: item.component ? item.component : 'component',
            pid: item.pid,
            disabled: false,
            description: 'xxx'
          });
          _this.flatten(item.children);
        });
      }
    },
    handleMenuClick({ key }) {
      // insale   outsale groundingAll export
      if (key == 'insale' || key == 'outsale') {
        let data = {
          "goods_ids": this.table.selectedRowKeys,
          "save_data": {
            "is_show": key == 'insale' ? 1 : 0
          }
        }
        BatchGoods(data).then(res => {
          if (res.error_code == 0) {
            this.$message.success('操作成功')
            this.query.page = 1
            this.getList()
          }
        })
      } else if (key == 'groundingAll') {//批量上架
        // 传值this.table.selectedRows  数组   跳转页面  

        console.log(this.table.selectedRowKeys.join());
        console.log(this.$store.state.groudingList);
        // this.$store.commit("setGroudingList", this.table.selectedRows);
        storage.set('groudingList', this.table.selectedRows);
        this.pageStatus = 'groundingAll'
        this.$router.replace({ path: '/mall/mallorder/verificationservice', query: { type: 4, ids: this.table.selectedRowKeys.join() } })

        // this.$router.replace({ path: '/shop/management/managementlist', query: { type: 2 } })
      }

    },

    handleMenuClickSearch(key) {
      if (key.id == 1) { //1财务  
        this.treeData = this.treeData1
        this.CheckSureObj.tabs = 1
        // this.permission_id_list = []
        // this.selectreeObj = this.selectreeObj

        this.permissionInfo.info.permission_id_list = this.permission_id_list

      }
      if (key.id == 2) { // 2 门店 
        this.treeData = this.treeData2
        this.CheckSureObj.tabs = 2
        // this.selectreeObj = this.selectreeObj2
        // this.permissionInfo.info.permission_id_list = []
        this.permissionInfo.info.permission_id_list = this.permission_id_list2
      }
      if (key.id == 3) { // 3 商城
        this.treeData = this.treeData3
        this.CheckSureObj.tabs = 3
        // this.selectreeObj = this.selectreeObj3
        // this.permissionInfo.info.permission_id_list = []
        this.permissionInfo.info.permission_id_list = this.permission_id_list3

      }

    },
    disabledDate(current) {
      // Can not select days before today and today
      return current && current < moment().endOf('day');
    },
    // table 多选
    onSelectChange(selectedRowKeys, selectedRows) {
      console.log(selectedRowKeys, selectedRows);
      this.table.selectedRowKeys = selectedRowKeys
      this.table.selectedRows = selectedRows
      // if (!selectedRows.length) {
      //   var isshow = false
      //   if (document.querySelector('.table-manage .ant-table-header .ant-checkbox-inner')) { document.querySelector('.table-manage .ant-table-header .ant-checkbox-inner').style.display = "none" }
      //   document.querySelector('.btn-padding').style.display = "none"
      // } else {
      //   isshow = true
      //   document.querySelector('.btn-padding').style.display = "inline-block"
      //   document.querySelector('.table-manage .ant-table-header .ant-checkbox-inner').style.display = "inline-block"
      // }
      // let times = setTimeout(() => {
      //   if (isshow) {

      //     // console.log(document.querySelectorAll('.ant-table-row-selected'));
      //     document.querySelectorAll('.ant-table-row-selected').forEach(el => {
      //       el.children[0].children[0].style.display = "inline-block"
      //       el.children[0].children[0].children[0].style.display = "inline-block"
      //       el.children[0].children[0].children[0].children[0].style.display = "inline-block"
      //       clearTimeout(times)
      //       times = ''
      //     })
      //   } else {
      //     document.querySelectorAll('.ant-table-body .ant-table-selection-column').forEach(el => {
      //       el.children[0].children[0].style.display = "none"
      //       clearTimeout(times)
      //       times = ''
      //     })
      //   }

      // });


    },
    getCheckboxPropsfn(record) {
      return {
        props: {
          disabled: record.status_text == "已结束" // 禁选项
        }
      };
    },
    setRowClass(record) {
      // console.log(record, 'record');
      if (record.card_status == 0) {
        // return "rowClass";
        return "failRowClass";
      } if (record.card_status == 1) {
        return "successRowClass";
      } else {
        return "successRowClass";
      }

    },
    customRow(record, index) {
      return {
        on: {
          click: event => {
            // document.querySelector('.ant-table-header .ant-checkbox-inner').style.display = "inline-block"
          },
          mouseenter: event => {
            // console.log(document.querySelector('.ant-table-header .ant-checkbox-inner'), "vxvxfvfxv");
            // console.log(event.target.children[0].children[0]);
            // console.log(event.target.children[0].children[0].children[0]);
            // event.target.children[0].children[0].style.display = "inline-block"
            // event.target.children[0].children[0].children[0].style.display = "inline-block"
            // event.target.children[0].children[0].children[0].children[0].style.display = "inline-block"
            // document.querySelector('.ant-table-header .ant-checkbox-inner').style.display = "inline-block"
            // document.querySelector('.ant-table-header .ant-table-selection-column').style.display = "inline-block"
          },
          mouseleave: (event) => {
            // console.log(event.target.className.indexOf('ant-table-row-selected'));
            // if (event.target.className.indexOf('ant-table-row-selected') < 0) {
            //   event.target.children[0].children[0].style.display = "none";
            //   // document.querySelector('.ant-table-header .ant-checkbox-inner').style.display = "none"
            //   // document.querySelector('.ant-table-header .ant-table-selection-column').style.display = "none"

            // }

          }

        },
      }

    },
    getfullHeight(val) {
      console.log(document.body.offsetHeight - val);
      return document.body.offsetHeight - val - 159 + 16
    },
    handleChange(value) {//多选框 用户来源
      this.query.page = 1
      // 选择项this.query.xx改变
      this.getList()
      console.log(`selected ${value}`);
    },
    handleChangedrag(info) {
      const status = info.file.status;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        this.$message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        this.$message.error(`${info.file.name} file upload failed.`);
      }
    },
    // 删除 
    handleBatch(key, record, title = "删除") {
      let self = this
      let deleteRules = DeLeteGoods
      const urlArr = [
        { name: deleteRules, key: 'delete', okType: 'primary', msg: '您将删除产品【' + record[0].goods_name + '】，是否确认删除？' },
      ]
      const activeObj = urlArr.filter(it => it.key == key)[0]
      self.$confirm({
        icon: 'exclamation-circle',
        closable: true,
        title,
        content: activeObj.msg,
        okText: '确定',
        closable: true,
        okType: activeObj.okType,
        cancelText: '取消',
        onOk() {
          const data = {
            goods_id: record[0].goods_id,
          };
          (activeObj.name)(data).then(res => {
            if (res.error_code == 0) {
              self.$message.success('操作成功')
              self.query.page = 1
              self.getList()
            }
          }).finally(r => {
          })
        },
        onCancel() {
        },
      });
    },
    // 商品品牌
    getBrands() {
      GetgoodsbrandsoptionsList().then(res => {
        console.log(res, "getBrands");
        if (res.error_code == 0) {
          this.brandsList = res.data
          this.brandsList.unshift({
            brand_icon: "meiye-routeicon-chart-pie",
            brand_id: 99999,
            brand_name: "全部",
            brand_sort: 1,
            brand_status: 1,
            created_at: "2023-04-20 16:30:25",
          })
        }
      })
    },
    // 
    getListSearch(e) {
      // console.log(e);
      this.query.page = 1
      if (e.activeStatus1.name == "商品名称") {
        this.query.goods_name = e.value
        this.query.goods_bn = undefined
        this.getList()
      } else {
        this.query.goods_name = undefined
        this.query.goods_bn = e.value
        this.getList()
      }
    },
    getList() {

      let self = this
      self.table.loading = true
      const params = {
        filter: {
          goods_name: self.query.goods_name,//商品名称
          goods_bn: self.query.goods_bn,//商品码
          goods_type: self.query.goods_type, //1客装 2院装 不传全部
          is_show: self.query.is_show, //1在售 0停售 不传为全部
          goods_brand: self.query.goods_brand == 99999 ? undefined : self.query.goods_brand, //品牌id 不传为全部
          goods_category: self.query.goods_category, //门店分类 不传为全部
          store_category_id: self.query.store_category_id,// 商城分类
          finance_category_id: self.query.finance_category_id,//财务分类
          start_data: self.query.start_data,
          end_data: self.query.end_data,
          goods_type: "guest",
        },
        page: self.query.page,
        limit: self.query.limit
      }

      return GetmanageGoodslist(params).then(res => {
        console.log(res.data, "data");
        const { list = [], total = 0 } = res.data
        self.table.tableData = list
        self.table.pagination = Object.assign({}, { pageSize: this.query.limit, current: this.query.page }, {
          total: total
        })
        console.log(self.table.tableData, "///self.table.tableData");
        self.onSelectChange([], [])
      }).finally(r => {
        self.table.loading = false
      })
    },
    // 商品树形列表
    getCompanyoptionList(e) {
      GetgoodsoptionsList().then(res => {
        this.treeRecursion(res.data, 'store_category_id')
        this.treeData3 = res.data
        console.log(this.treeData3, '>>>this.coptionsList3');
        if (!e) {
          // this.query.company_parent = res.data[0].company_id
          // this.permissionInfo.info.company_parent = res.data[0].company_id
          this.getList()
        }

      })
    },
    //  门店分类 树形列表
    getCompanyoptionList(e) {
      GetgoodsoptionsList().then(res => {
        this.treeRecursion(res.data, 'category_id')
        this.treeData2 = res.data
        console.log(this.treeData2, '>>>this.coptionsList');
        this.treeRecursion2(this.treeData2, 'disabled')

      })
    },
    //  财务分类 列表
    getCompanyoptionList2(e) {
      TreetypeFinanceList().then(res => {
        this.treeRecursion(res.data, 'finance_category_id')
        this.treeData1 = res.data
        this.treeData = res.data
        console.log(this.treeData, '>>>this.treeData2');
      })
    },
    //  商城分类 树形列表
    getCompanyoptionList3(e) {
      TreetypeMallList().then(res => {
        this.treeRecursion(res.data, 'store_category_id');
        this.treeData3 = res.data
        this.treeRecursion2(this.treeData3, 'disabled')
        console.log(this.treeData, '>>>this.coptionsList');


      })
    },

    // 递归  disabled   disableCheckbox
    treeRecursion2(data, i) {
      data.forEach(el => {
        // 把数字类型转为字符串  
        if (el.category_parent_path.includes(',')) {
          el[i] = false
        } else {
          el[i] = true
        }
        if (el.children && el.children.length && !el.category_parent_path.includes(',')) this.treeRecursion2(el.children, 'disableCheckbox');
      })
    },

    // 递归改变id key number
    treeRecursion(data, str) {
      data.forEach(el => {
        // 把数字类型转为字符串 
        if (typeof (el[str]) == 'number') {
          el[str] = el[str].toString()
        }
        if (el.children && el.children.length) this.treeRecursion(el.children);
      })
    },




    tableChanged(pagination, filters, sorter, { currentDataSource }) {

      const { current, pageSize } = pagination
      const { field, order } = sorter
      // 此处 筛选项 order排序对应字段进项排序 请求接口
      this.query = Object.assign(this.query, { limit: Number(pageSize), page: Number(current) })
      // self.query.page = 1
      this.getList()

    },

    changeStatus($event, e) {
      let obj = {}
      this.table.tableData.forEach(el => {
        if (e.role_id == el.role_id) {
          el.role_status = $event ? 1 : 0
          obj = el
        }
      })
      this.permissionInfo.type = 3 //编辑
      this.permissionInfo.info = {
        role_id: obj.role_id,
        // system_id: obj.system_id,
        role_name: obj.permission_name,
        permission_id_list: [],
        role_status: obj.role_status
      }
      this.okclickmod()
    },
    onChangePermissiontype(e) {
      console.log('radio checked', e.target.value, this.permissionInfo.info.permission_type);
    },
    // 提交按钮
    okclickmod(e) {
      function fn(beforeData, addorupdateSpecFlag) {
        var afterData = []
        beforeData.forEach(item => {
          let flag
          if (addorupdateSpecFlag == 'add') {
            flag = afterData.find(item1 => item1.product_id === item.product_id)
          } else {
            flag = afterData.find(item1 => item1.product_id_add === item.product_id_add)
          }

          if (!flag) {
            if (addorupdateSpecFlag == 'add') {
              afterData.push({
                product_id: item.product_id,
                product_attr: [item]
              })
            } else {
              afterData.push({
                product_id_add: item.product_id_add,
                product_attr: [item]
              })
            }

          } else {
            flag.product_attr.push(item)
          }
        })
        console.log(afterData, 'afterDataafterData');
        return afterData

      }
      function chkstrlen(str) {
        var strlen = false;
        for (var i = 0; i < str.length; i++) {
          if (str.charCodeAt(i) > 255) //如果是汉字， 
            strlen = true;
        }
        return strlen;
      }
      // 这里要搞事情 goods_specs.isSingle  生成多规格商品列表 goodSpecstableData
      if (this.goods_specs.isSingle !== 0) {
        this.permissionInfo.info.goods_attr_val = this.goodSpecstableData
        let arry = []
        this.permissionInfo.info.goods_attr_val


        this.permissionInfo.info.goods_attr_val.forEach(el => {
          this.permissionInfo.info.goods_attr.forEach((m, n) => {
            let obj = {}
            if (el.hasOwnProperty(m.attr_name)) {
              //   
              obj[m.attr_name] = el[m.attr_name]
              obj.product_id = el.product_id
              obj.product_id_add = el.product_id_add
              arry.push(obj)
            }
          })
        })

        console.log(arry, "mainarr....");
        // arry=[ {"颜色":"白色"}， {"颜色":"黑色"}，{"尺寸":"m"}，{"尺寸":"l"}  ]
        let mainarr = fn(arry, this.addorupdateSpecFlag)
        mainarr.forEach(m => {
          let s = JSON.stringify(m.product_attr)
          m.product_attr = JSON.parse(s.replace(/},{/g, ","))
        })
        //   
        console.log(mainarr, "mainarr....");
        this.permissionInfo.info.goods_attr_val.forEach(el => {
          mainarr.forEach((m, n) => {
            if (el.product_id == m.product_id || el.product_id_add == m.product_id_add) {
              el.product_attr = m.product_attr[0]
              for (let item in el.product_attr) {
                let names = `"${item}"`
                el.product_attr[names] = el.product_attr[item]
                delete el.product_attr[item]
              }
            }
          })
        })

        console.log(this.permissionInfo.info.goods_attr_val, " this.permissionInfo.info.goods_attr_val");
        this.permissionInfo.info.goods_attr_val.forEach((el, idx) => {
          delete el.product_id
          delete el.product_id_add
          delete el.product_attr['"product_id"']
          delete el.product_attr['"product_id_add"']
          delete el.product_attr['"created_at"']
          delete el.product_attr['"updated_at"']
          delete el.created_at
          delete el.updated_at
          for (var pl in el) { //数组对象遍历
            console.log(pl); //获取key
            console.log(el[pl]) //获取key的值
            let flag = chkstrlen(pl) //判断是否中文字符
            if (flag) {
              delete el[pl]
            }
          }
        })
        //   
        console.log(this.permissionInfo.info.goods_attr_val);

      } else {
        this.permissionInfo.info.goods_attr = []
        delete this.goodSpecsSingletableData[0].product_id
        delete this.goodSpecsSingletableData[0].product_id_add
        this.permissionInfo.info.goods_attr_val = this.goodSpecsSingletableData
      }
      delete this.permissionInfo.info.pic_url_loading

      // 区分新增 修改接口
      if (this.permissionInfo.type == 3) {
        delete this.permissionInfo.info.created_at
        delete this.permissionInfo.info.updated_at
        let data = {
          ...this.permissionInfo.info
        }

        UpdateGoods(data).then(res => {
          if (res.error_code == 0) {
            this.$message.success(res.data.message)
            this.permissionInfo.visible = false
            this.getList()
          }
        })
      } else {
        //新增 
        let data = {
          ...this.permissionInfo.info
        }
        delete data.product_id
        delete data.product_id_add
        CreateGoods(data).then(res => {
          if (res.error_code == 0) {
            this.$message.success(res.data.message)
            this.permissionInfo.visible = false
            this.getList()
          }
        }).catch(err => {
          console.log(err);
        })
      }
    },
    async addRules(e, i) {


      if (i == 3) {//编辑 
        if (this.$route.query.goods_type == 1) {
          // 跳转到商品中心的 编辑产品编辑页面 shop/management/managementlist?type=3&id=137
          let global_menu_obj = {
            "menu_id": 31,
            "menu_icon": "meiye-routeicon-layers",
            "menu_title": "商品中心",
            "path": "shop",
            "menu_children": [
              {
                "id": 32,
                "pid": 31,
                "title": "产品列表",
                "icon": "meiye-routeicon-chart-pie",
                "path": "management",
                "component": "management",
                "params": "",
                "children": [
                  { "id": 35, "pid": 32, "title": "产品列表", "icon": "meiye-routeicon-chart-pie", "path": "managementlist", "component": "management", "params": "" }, { "id": 36, "pid": 32, "title": "赠送产品", "icon": "meiye-routeicon-folder-close", "path": "give", "component": "management_gifts", "params": "" }]
              },
              { "id": 33, "pid": 31, "title": "品牌管理", "icon": "meiye-routeicon-complete", "path": "brand", "component": "brand", "params": "" }, { "id": 34, "pid": 31, "title": "分类管理", "icon": "meiye-routeicon-chart-pie", "path": "type", "component": "typeFinance", "params": "", "children": [{ "id": 47, "pid": 34, "title": "财务分类", "icon": "meiye-routeicon-column-vertica", "path": "typeFinance", "component": "typeFinance", "params": "" }, { "id": 48, "pid": 34, "title": "门店分类", "icon": "meiye-routeicon-check-item", "path": "typeStore", "component": "typeStore", "params": "" }, { "id": 49, "pid": 34, "title": "商城分类", "icon": "meiye-routeicon-discount", "path": "typeMall", "component": "typeMall", "params": "" }] }, { "id": 39, "pid": 31, "title": "服务列表", "icon": "meiye-routeicon-layers", "path": "service", "component": "service", "params": "", "children": [{ "id": 40, "pid": 39, "title": "服务列表", "icon": "meiye-routeicon-column-horizon", "path": "servicelist", "component": "service", "params": "" }, { "id": 41, "pid": 39, "title": "赠送服务", "icon": "meiye-routeicon-chart-pie", "path": "service_gifts", "component": "service_gifts", "params": "" }] }, { "id": 42, "pid": 31, "title": "方案列表", "icon": "meiye-routeicon-complete", "path": "plan", "component": "plan", "params": "", "children": [{ "id": 43, "pid": 42, "title": "方案列表", "icon": "meiye-routeicon-check-item", "path": "planlist", "component": "plan", "params": "" }, { "id": 44, "pid": 42, "title": "赠送方案", "icon": "meiye-routeicon-data-view", "path": "plan_gifts", "component": "plan_gifts", "params": "" }] }, { "id": 45, "pid": 31, "title": "储值卡列表", "icon": "meiye-routeicon-work-copy", "path": "storedvaluecard", "component": "storedvaluecard", "params": "" }]
          }
          storage.set('activeTabsKey', "产品列表")
          storage.set('global_menu_obj', global_menu_obj)
          Msg.$emit('secondery', global_menu_obj)
          Msg.$emit('changethirdaryShow', false)
          this.$router.replace({ path: '/shop/management/managementlist', query: { type: 3, id: 137 } })

          this.$router.go(0);
        }
        if (this.$route.query.goods_type == 2) {
          // 跳转到商品中心的 编辑服务编辑页面
          // this.$router.replace({ path: '/shop/service/servicelist', query: { type: 3, id: 136 } })
          // Msg.$emit('changethirdaryShow', false)
          let global_menu_obj = {
            "menu_id": 31, "path": "shop",
            "menu_icon": "meiye-routeicon-layers",
            "menu_title": "商品中心",
            "menu_children": [
              {
                "id": 32, "pid": 31, "title": "产品列表", "icon": "meiye-routeicon-chart-pie",
                "path": "management", "component": "management", "params": "",
                "children": [{ "id": 35, "pid": 32, "title": "产品列表", "icon": "meiye-routeicon-chart-pie", "path": "managementlist", "component": "management", "params": "" }, { "id": 36, "pid": 32, "title": "赠送产品", "icon": "meiye-routeicon-folder-close", "path": "give", "component": "management_gifts", "params": "" }]
              },
              { "id": 33, "pid": 31, "title": "品牌管理", "icon": "meiye-routeicon-complete", "path": "brand", "component": "brand", "params": "" }, { "id": 34, "pid": 31, "title": "分类管理", "icon": "meiye-routeicon-chart-pie", "path": "type", "component": "typeFinance", "params": "", "children": [{ "id": 47, "pid": 34, "title": "财务分类", "icon": "meiye-routeicon-column-vertica", "path": "typeFinance", "component": "typeFinance", "params": "" }, { "id": 48, "pid": 34, "title": "门店分类", "icon": "meiye-routeicon-check-item", "path": "typeStore", "component": "typeStore", "params": "" }, { "id": 49, "pid": 34, "title": "商城分类", "icon": "meiye-routeicon-discount", "path": "typeMall", "component": "typeMall", "params": "" }] },
              { "id": 39, "pid": 31, "title": "服务列表", "icon": "meiye-routeicon-layers", "path": "service", "component": "service", "params": "", "children": [{ "id": 40, "pid": 39, "title": "服务列表", "icon": "meiye-routeicon-column-horizon", "path": "servicelist", "component": "service", "params": "" }, { "id": 41, "pid": 39, "title": "赠送服务", "icon": "meiye-routeicon-chart-pie", "path": "service_gifts", "component": "service_gifts", "params": "" }] },
              { "id": 42, "pid": 31, "title": "方案列表", "icon": "meiye-routeicon-complete", "path": "plan", "component": "plan", "params": "", "children": [{ "id": 43, "pid": 42, "title": "方案列表", "icon": "meiye-routeicon-check-item", "path": "planlist", "component": "plan", "params": "" }, { "id": 44, "pid": 42, "title": "赠送方案", "icon": "meiye-routeicon-data-view", "path": "plan_gifts", "component": "plan_gifts", "params": "" }] }, { "id": 45, "pid": 31, "title": "储值卡列表", "icon": "meiye-routeicon-work-copy", "path": "storedvaluecard", "component": "storedvaluecard", "params": "" }]
          }

          let seconderyMenuObj = { "children": [{ "id": 40, "pid": 39, "title": "服务列表", "icon": "meiye-routeicon-column-horizon", "path": "servicelist", "component": "service", "params": "", "fullPath": "/shop/service/servicelist" }, { "id": 41, "pid": 39, "title": "赠送服务", "icon": "meiye-routeicon-chart-pie", "path": "service_gifts", "component": "service_gifts", "params": "", "fullPath": "/shop/service/service_gifts" }], "component": "service", "icon": "meiye-routeicon-layers", "id": 39, "params": "", "path": "service", "pid": 31, "title": "服务列表", "fullPath": "/shop/service" }

          storage.set('seconderyMenuObj', seconderyMenuObj)
          storage.set('activeTabsKey', "服务列表")
          storage.set('global_menu_obj', global_menu_obj)
          Msg.$emit('secondery', global_menu_obj)
          Msg.$emit('changethirdaryShow', false)
          this.$router.replace({ path: '/shop/service/servicelist', query: { type: 3, id: 136 } })
          this.$router.go(0);


        }
        if (this.$route.query.goods_type == 3) {
          this.association.visible = true
          // 储蓄卡
          this.association.config = this.association.config
        }
        if (this.$route.query.goods_type == 4) {
          this.association.visible = true
          // 方案
          this.association.config = this.association.config2
        }

      } else if (i == 1) {
        this.pageStatus = 'add'
        // this.permissionInfo.title = '添加商品'
        this.addorupdateSpecFlag = 'add'
        this.permissionInfo.type = 1
        delete this.permissionInfo.info.goods_id
        // this.$router.replace({ path: '/mall/mallorder/verificationservice', query: { type: i } })
        console.log(this.permissionInfo);

      } else if (i == 2) {
        // this.permissionInfo.title = '上架'
        // this.pageStatu = 'grounding'
        // this.$router.replace({ path: '/mall/mallorder/verificationservice', query: { type: i, id: e.goods_id, bn: e.goods_bn, name: e.goods_name } })

      }
      // Msg.$emit('changethirdaryShow', false)
    },
    // 打开详情
    openDatails() {
      GoodsInfo({ goods_id: this.permissionInfo.info.goods_id }).then(res => {
        this.permissionInfo.info = Object.assign(this.permissionInfo.info, res.data)
        this.permissionInfo.info.goods_type = this.permissionInfo.info.goods_type + ''
        this.permissionInfo.info.goods_brand = this.permissionInfo.info.goods_brand + ''
        this.permissionInfo.info.goods_supplier = this.permissionInfo.info.goods_supplier + ''
        this.permissionInfo.info.goods_slider = JSON.parse(this.permissionInfo.info.goods_slider)
        this.permissionInfo.info.goods_attr_val.forEach((el, idx) => {
          el.product_id_add = idx
        })
        let fileList = []
        // 回显轮播图
        this.permissionInfo.info.goods_slider.forEach((val, key) => {
          fileList.push({
            uid: '-' + key,
            // name: '' + val.name, // 文件名
            status: 'done',
            url: '' + val
          })
        })
        this.$refs.uploadCom.fileList = fileList
        // 单规格 or 多规格
        if (this.permissionInfo.info.goods_attr.length) {//多规格
          this.goods_specs.isSingle = 2
          this.goods_specs.new_goods_specs_list = []
          this.goods_specs.goods_specs_keys_list = []
          //  这里多规格 回显设置规格的值列表
          this.permissionInfo.info.goods_attr.forEach((el, idx) => {
            let obj = {
              id: "p" + idx,
              attr_name: el.attr_name,
              attr_values: el.attr_values
            }
            this.goods_specs.new_goods_specs_list.push(obj)
          })
          // 还需要回显规格商品表
          this.goodSpecstableData = []

          // 处理 goods_specs_clomns
          this.goods_specs_clomns = []
          let arri1 = []
          this.permissionInfo.info.goods_attr.forEach((el, idx) => {
            let obj = {
              title: el.attr_name,
              dataIndex: el.attr_name,
              key: el.attr_name,
              ellipsis: true,
              scopedSlots: { customRender: el.attr_name },
            }
            let object1 = {
              attr_name: el.attr_name,
              attr_values: el.attr_values,
              product_id: el.product_id
            }
            this.goods_specs_clomns.push(obj)
            arri1.push(object1)
          })
          this.permissionInfo.info.goods_attr = arri1
          this.goods_specs_clomns = [... this.goods_specs_clomns, ...this.goods_specs__sigle_clomns]
          console.log(this.goods_specs_clomns, "...this.goods_specs_clomns");
          let newResult = arri1.reduce((a, b, c) => {
            let res = []
            a.map(x => {
              b.attr_values.map(y => {
                res.push({ ...x, [b.attr_name]: y })
              })
            })
            return res
          }, [{}])
          //   回显 多规格列表 形式
          newResult.forEach((el, idx) => {
            let obj = {
              product_id: this.permissionInfo.info.goods_attr_val[idx].product_id,
              product_bn: this.permissionInfo.info.goods_attr_val[idx].product_bn,
              product_attr: this.permissionInfo.info.goods_attr_val[idx].product_attr,
              product_is_def: this.permissionInfo.info.goods_attr_val[idx].product_is_def,
              product_image: this.permissionInfo.info.goods_attr_val[idx].product_image,
              product_price: this.permissionInfo.info.goods_attr_val[idx].product_price,
              product_cost_price: this.permissionInfo.info.goods_attr_val[idx].product_cost_price,
              product_market_price: this.permissionInfo.info.goods_attr_val[idx].product_market_price,
              product_stock: this.permissionInfo.info.goods_attr_val[idx].product_stock
            }
            el = Object.assign(el, obj)
          })
          this.goodSpecstableData = newResult
          // console.log(this.goods_specs.new_goods_specs_list, "goods_specs.new_goods_specs_list");

        } else {//单规格
          this.goods_specs.isSingle = 1
          this.goodSpecsSingletableData = this.permissionInfo.info.goods_attr_val
        }

      })
    },
    // 递归改变id key number
    treeRecursion(data) {
      data.forEach(el => {
        // 把数字类型转为字符串 
        if (typeof (el.company_id) == 'number') {
          el.company_id = el.company_id.toString()
        }
        if (el.children && el.children.length) this.treeRecursion(el.children);
      })
    },
    // 自定义展开关闭图标
    customExpandIcon(props) {
      console.log(props)
      // if(props.record.children.length > 0){
      if (props.expanded) {
        return <a style={{ color: 'black', marginRight: 8 }} onClick={e => {
          props.onExpand(props.record, e);
        }}><a-button type="link">关闭详情</a-button></a>
      } else {
        return <a style={{ color: 'black', marginRight: 8 }} onClick={e => {
          props.onExpand(props.record, e);
        }}><a-button type="link">查看详情</a-button></a>
      }
      // }else{
      //     return <span style={{marginRight:8 }}></span>
      // }
    },

    beforeUpload(file, fileList) {
      //   
      var self = this
      return new Promise((resolve, reject) => {
        const isLt1M = file.size / 1024 / 1024 > 1
        if (isLt1M) {
          self.$message.error('上传文件大于1MB!')
          reject(false)
        }
        if (fileList.length > 1) {
          self.$message.error('只能上传一张图片！')
          reject(false)
        }
        var fileNames = file.name.split('.')
        var fileType = fileNames[fileNames.length - 1].toLocaleLowerCase()
        var extList = ['jpg', 'png', 'jpeg']
        if (!extList.find((item) => item == fileType)) {
          self.$message.error('只能上传.jpg/.png/.jpeg类型的图片！')
          reject(false)
        }
        resolve(true)
      }).finally(() => {
      })
    },
    // 商品头图
    async uploadFiles(info) {
      const fileInfo = {
        uid: info.file.uid,
        name: info.file.name,
        status: "uploading",
        response: "",
        url: "",
      };
      let uploadApiUrl = this.baseUrl + '/upload'
      const res = await this.uploadFilesToServer(
        uploadApiUrl,
        "file",
        info.file
      );
      if (res.error_code == 0) {
        this.permissionInfo.info.goods_image = res.data.url
      } else {
        this.$message.error(res.error.message)
      }
    },
    // 规格图
    async uploadspecFiles(info, record, flag) {
      // console.log(info)
      // console.log(record); 
      // console.log(this.goodSpecstableData);
      const fileInfo = {
        uid: info.file.uid,
        name: info.file.name,
        status: "uploading",
        response: "",
        url: "",
      };
      let uploadApiUrl = this.baseUrl + '/upload'
      const res = await this.uploadFilesToServer(
        uploadApiUrl,
        "file",
        info.file
      );
      if (res.error_code == 0) {
        if (flag) {
          this.goodSpecsSingletableData[0].product_image = res.data.url
        } else {
          this.goodSpecstableData.forEach(el => {
            if (el.product_id == record.product_id || el.product_id_add == record.product_id_add) {
              el.product_image = res.data.url
            }
          })
        }


        // this.permissionInfo.info.goods_image = res.data.url
        console.log(this.goodSpecstableData);
      } else {
        this.$message.error(res.error.message)
      }


    },
    uploadFilesToServer(uploadApiUrl, fileName, files) {
      let formData = new FormData();
      formData.append(fileName, files);
      //添加请求头
      const headers = {
        "Content-Type": "multipart/form-data",
      };
      //配置头
      const request = axios.create({
        headers: headers,
      });
      //开始上传
      return request
        .post(uploadApiUrl, formData)
        .then((response) => {
          return Promise.resolve(response.data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    async uploadFilesDrag(info) {

      const fileInfo = {
        uid: info.file.uid,
        name: info.file.name,
        status: "uploading",
        response: "",
        url: "",
      };
      let uploadApiUrl = this.baseUrl + '/upload'
      const res = await this.uploadFilesToServer(
        uploadApiUrl,
        "file",
        info.file
      );
      if (res.error_code == 0) {
        this.permissionInfo.info.goods_image = res.data.url
      } else {
        this.$message.error(res.error.message)
      }
    },
    callback(key) {
      console.log(key);
    },

    getDelImageId(val) {
      if (this.permissionInfo.info.goods_slider && this.permissionInfo.info.goods_slider.length > 0) {

      } else {
        this.permissionInfo.info.goods_slider = []
      }
      this.permissionInfo.info.goods_slider.push(val)
    },
    getImageshow(val) {
      this.pulsshow = val
    },
    delDelimageId(index) {
      this.permissionInfo.info.goods_slider.forEach((val, key) => {
        if (index === key) {
          this.permissionInfo.info.goods_slider.splice(key, 1)
        }
      })
    },
    // 获取到重新排序后的图片
    handleDraggable(e) {
      const imgDrag = []
      for (var i = 0; i < e.length; i++) {
        // var a = e[i].url.split('/')
        imgDrag.push(e[i].url)
      }
      this.permissionInfo.info.goods_slider = imgDrag
    },
    //往表格里面加规格列表 
    hideModal() {
      var arr = []
      var arr_goods_attr = []
      this.goods_specs.new_goods_specs_list
      this.goods_specs.goods_specs_list
      //   
      arr = [...this.goods_specs.new_goods_specs_list, ...this.goods_specs.goods_specs_keys_list]

      // todo 以后这里需要把规格列表传给后台 成功之后 1表单头，2表单体数据

      let arri1 = []
      // coluns
      let columns = []
      let specs_clomns = [
        {
          title: '规格图',
          dataIndex: 'product_image',
          key: 'product_image',
          ellipsis: true,
          scopedSlots: { customRender: 'product_image' },
        },
        {
          title: '*标准单价',
          dataIndex: 'product_price',
          key: 'product_price',
          ellipsis: true,
          scopedSlots: { customRender: 'product_price' },
        },
        {
          title: '货品码',
          dataIndex: 'product_bn',
          key: 'product_bn',
          ellipsis: true,
          scopedSlots: { customRender: 'product_bn' },
        },
      ]
      arr.forEach((el, index) => {
        let object1 = {
          attr_name: el.attr_name,
          attr_values: el.attr_values,
        }
        let object2 = {
          title: el.attr_name,
          dataIndex: el.attr_name,
          key: el.attr_name,
          ellipsis: true,
          scopedSlots: { customRender: el.attr_name },
        }
        arri1.push(object1)
        columns.push(object2)
      })
      this.permissionInfo.info.goods_attr = arri1 //规格
      // 动态生成表头
      this.goods_specs_clomns = [...columns, ...specs_clomns]

      let newResult2 = []
      let newResult = arri1.reduce((a, b, c) => {
        let res = []
        a.map(x => {
          b.attr_values.map(y => {
            res.push({ ...x, [b.attr_name]: y })
          })
        })
        return res
      }, [{}])
      // newResult2 = Object.assign(newResult2, newResult)
      console.log(newResult, 1, newResult2)
      // 动态生成 商品规格表 
      // 编辑条件下 比较与旧版的商品规格表 把数据贴上去
      if (this.addorupdateSpecFlag == 'update') {
        newResult.forEach((el, idx) => {
          this.permissionInfo.info.goods_attr_val.forEach((m, n) => {
            //   
            let reg = /\\"/g;
            let a = JSON.stringify(m.product_attr).replace(reg, '');
            console.log(JSON.stringify(el) == a)
            if (JSON.stringify(el) == a) {
              newResult[idx] = Object.assign(m, el)
              // newResult[idx] = m
            }

          })

        })

        newResult.forEach((el, idx) => {
          let obj = {
            product_id_add: idx,
            product_bn: '',
            product_attr: { "颜色": "白色", "大小": "黑色" },
            product_is_def: 1,
            product_image: '',
            product_price: '',
            product_cost_price: '',
            product_market_price: '',
            product_stock: ''
          }
          let a = { ...obj, ...el }
          newResult2.push(a)
        })
        console.log(newResult, 2)

        this.goodSpecstableData = newResult2
      } else {
        this.goodSpecstableData = newResult2
      }
      this.goods_specs.ischooseModehow = false
    },
    changeGoodsSpecs(e, i) {
      this.goods_specs.goods_specs_values_list = []
      // goods_specs.new_goods_specs_list.push
      let self = this
      this.goods_specs.goods_specs_list.forEach(el => {
        if (el.id == e) {
          self.goods_specs.goods_specs_values_list = el.attr_values
          self.goods_specs.new_goods_specs_list.push(el)
        }
      })
      // console.log(this.goods_specs.goods_specs_values_list, "goods_specs_values_list");
      // console.log(self.goods_specs.goods_specs_keys_list, " self.goods_specs.goods_specs_keys_list");
    },
    onqyChange(e, it, idex) {
      console.log(this.goods_specs.goods_specs_values_list);
      let self = this
      self.goods_specs.goods_specs_values_list[idex] = e.target.innerHTML
    },
    legalObjadd() {
      let flag = false
      let self = this
      this.goods_specs.goods_specs_values_list.forEach(el => {
        if (!el) {
          flag = true
        }
      })
      if (flag && self.goods_specs.goods_specs_values_list.length > 0) {
        this.$warning({
          title: '提示',
          content: h => {
            return h('div', [
              h(
                'span',
                [' 信息尚未填写，请完善内容后进入下一步。']
              ),
            ])
          },

          okText: '好的',
          okType: 'primary',
          // cancelText: '取消',
          onOk() {
            // self.qystep = 2
          },
        });
        return false
      }
      console.log(this.goods_specs.goods_specs_values_list);
      this.goods_specs.goods_specs_values_list.push('')
    },
    addnewgoodsspecs(idx, n) {
      let flag = false
      let self = this
      this.goods_specs.new_goods_specs_list[idx].attr_values.forEach(el => {
        if (!el) {
          flag = true
        }
      })
      if (flag && self.goods_specs.new_goods_specs_list[idx].attr_values.length > 0) {
        this.$warning({
          title: '提示',
          content: h => {
            return h('div', [
              h(
                'span',
                [' 信息尚未填写，请完善内容后操作。']
              ),
            ])
          },

          okText: '好的',
          okType: 'primary',
          // cancelText: '取消',
          onOk() {
          },
        });
        return false
      }
      console.log(this.goods_specs.new_goods_specs_list[idx].attr_values);
      this.goods_specs.new_goods_specs_list[idx].attr_values.push('')
    },
    // 删除新增的规格名 规格值
    deleteNewspecs(e) {
      // delete this.goods_specs.new_goods_specs_list[e]
      this.goods_specs.new_goods_specs_list.splice(e, 1)
      console.log(this.goods_specs.new_goods_specs_list);
    },
    closegoodsSpecsValues(e) {
      this.goods_specs.goods_specs_values_list.splice(e, 1)
      console.log(this.goods_specs.goods_specs_values_list, "this.goods_specs.goods_specs_values_list");
    },
    deletenewGoodsSpecs(idx, n) {
      this.goods_specs.new_goods_specs_list[idx].attr_values.splice(n, 1)
    },
    // 批量生成规格表格  设置 单价 和 货品码
    createdSpecsTable() {
      let self = this
      // this.goodSpecstableData  
      this.goodSpecstableData.forEach((el, idx) => {
        self.goodSpecstableData[idx].product_price = self.goods_specs.price
        self.goodSpecstableData[idx].product_bn = self.goods_specs.barcode
        // el.product_price = this.goods_specs.price
        // el.product_bn = this.goods_specs.barcode
      })
    },
    // 改变 某一个单价/条码
    changeSingeSpecIpt(record, i, flag) {
      console.log(record, i, flag, "val,record");
      //   
      console.log(this.goodSpecstableData, 'goodSpecstableData')
      if (flag) {
        this.goodSpecsSingletableData.forEach((el, idx) => {
          if (el.product_id == record.product_id || el.product_id_add == record.product_id_add) {
            this.goodSpecsSingletableData[idx][i] = record[i]
          }
        })
      } else {
        this.goodSpecstableData.forEach((el, idx) => {
          if (el.product_id == record.product_id || el.product_id_add == record.product_id_add) {
            this.goodSpecsSingletableData[idx][i] = record[i]
          }
        })
      }
    },
    changeSingeSpecIpt1(e, i, j) {
      console.log(e, i, j);
    },
    getUser(e) {
      console.log(e);
      this.permissionInfo.info.goods_content = e
    },

    // 清除 类别  分类 品牌  状态 恢复 初始值
    clearfliterAll() {
      // 清除分类
      this.flitList[1].value = []
      this.selectedKeys = []
      this.expandedKeys = []
      this.autoExpandParent = false
      this.backupsExpandedKeys = []
      this.permissionInfo.info.permission_id_list = []

      // // 清除状态
      // this.activeStatus_status = []
      // // 清除类别
      // this.activeStatus_catagory = []
      // // 清除品牌
      // this.activeStatus_brand = [] 
      this.flitList = [
        { key: '类别', value: [], paramas: 'goods_type' },
        { key: '分类', value: [], paramas: 'goods_category' },
        { key: '状态', value: [], paramas: 'is_show' },
        { key: '品牌', value: [], paramas: 'goods_brand' },
        { key: '上架时间', value: [], paramas: 'data_time' },
        { key: '下架时间', value: [], paramas: 'data_time' },
      ]
      this.isAllLength = ""
      this.query = {
        goods_name: undefined,//商品名称
        goods_bn: undefined,//商品码
        goods_type: undefined, //1客装 2院装 不传全部
        is_show: undefined, //1在售 0停售 不传为全部
        goods_brand: undefined, //品牌id 不传为全部
        goods_category: undefined, //分类id 不传为全部
        limit: this.query.limit,
        page: 1,
      },
        // todu 调取更新list的接口
        this.getList()
    },
    clearfliterSingle(e) {
      console.log(e);
      if (e) {
        // 单个清除 根据业务 去除 
        this.query[e.paramas] = undefined
        this.flitList.forEach(el => {
          if (el.paramas == e.paramas) {
            el.value = []
          }
        })
        // 分类筛选项
        if (e.key == "分类") {
          this.permissionInfo.info.permission_id_list = this.permission_id_list = this.permission_id_list2 = this.permission_id_list3 = []
        }
        if (e.key == "上架时间" || e.key == "下架时间") {
          this.query.start_data = undefined
          this.query.end_data = undefined
        }
        this.query.page = 1
        this.getList()
      } else {
        this.permissionInfo.info.permission_id_list = this.permission_id_list = this.permission_id_list2 = this.permission_id_list3 = []
        // 全部清除
        this.clearfliterAll()
      }

    },
    // getTypestreeListSearch
    getTypestreeListSearch(e) {

    },

    // 搜索框配合 分类 树形
    getTypestreeListSearch(e) {
      console.log(e);
      var _this = this;
      //添加这行代码是为了只点击搜索才触发
      _this.searchValue = e.value;
      //如果搜索值为空，则不展开任何项，expandedKeys置为空
      //如果搜索值不位空，则expandedKeys的值要为搜索值的父亲及其所有祖先
      if (_this.searchValue === '') {
        _this.expandedKeys = [];
      } else {
        //首先将展开项与展开项副本置为空
        _this.expandedKeys = [];
        _this.backupsExpandedKeys = [];
        //获取所有存在searchValue的节点
        let candidateKeysList = _this.getkeyList(_this.searchValue, _this.treeData, []);
        //遍历满足条件的所有节点
        candidateKeysList.map(
          item => {
            //获取每个节点的母亲节点
            var key = _this.getParentKey(item, _this.treeData);
            //当item是最高一级，父key为undefined，将不放入到数组中
            //如果母亲已存在于数组中，也不放入到数组中
            if (key && !_this.backupsExpandedKeys.some(item => item === key))
              _this.backupsExpandedKeys.push(key);
          }
        )
        let length = this.backupsExpandedKeys.length;
        for (let i = 0; i < length; i++) {
          _this.getAllParentKey(_this.backupsExpandedKeys[i], _this.treeData)
        }
        _this.expandedKeys = _this.backupsExpandedKeys.slice();
      }
    },
    //获取节点中含有value的所有key集合
    getkeyList(value, tree, keyList) {
      //遍历所有同一级的树
      for (let i = 0; i < tree.length; i++) {
        let node = tree[i];
        //如果该节点存在value值则push
        if (node.category_name.indexOf(value) > -1) {
          keyList.push(node.category_id);
        }
        //如果拥有孩子继续遍历
        if (node.children) {
          this.getkeyList(value, node.children, keyList);
        }
      }
      //因为是引用类型，所有每次递归操作的都是同一个数组
      return keyList;
    },
    //该递归主要用于获取key的父亲节点的key值
    getParentKey(key, tree) {
      let parentKey, temp;
      //遍历同级节点
      for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        if (node.children) {
          //如果该节点的孩子中存在该key值，则该节点就是我们要找的父亲节点
          //如果不存在，继续遍历其子节点
          if (node.children.some(item => item.category_id === key)) {
            parentKey = node.category_id;
          } else if (temp = this.getParentKey(key, node.children)) {
            //parentKey = this.getParentKey(key,node.children)
            //改进，避免二次遍历
            parentKey = temp;
          }
        }
      }
      return parentKey;
    },
    //获取该节点的所有祖先节点
    getAllParentKey(key, tree) {
      var parentKey;
      if (key) {
        //获得父亲节点
        parentKey = this.getParentKey(key, tree);
        if (parentKey) {
          //如果父亲节点存在，判断是否已经存在于展开列表里，不存在就进行push
          if (!this.backupsExpandedKeys.some(item => item === parentKey)) {
            this.backupsExpandedKeys.push(parentKey);
          }
          //继续向上查找祖先节点
          this.getAllParentKey(parentKey, tree);
        }
      }
    },
    onExpand(expandedKeys) {
      //用户点击展开时，取消自动展开效果
      this.expandedKeys = expandedKeys;
      this.autoExpandParent = false;
    },
    onCheck(checkedKeys, info) {
      console.log(info.node.dataRef);
      console.log('onCheck', checkedKeys);
      this.CheckSureObj = {
        checkedKeys: checkedKeys,
        info: info,
        tabs: this.CheckSureObj.tabs
      }
    },
    onCheckSure() {
      let { checkedKeys, info, tabs } = this.CheckSureObj
      let that = this
      that.permissionInfo.info.permission_id_list = []
      that.flitList[1].value = []
      that.flitList[1].paramas = 'finance_category_id'

      let arrobj = []
      if (info) {
        info.checkedNodes.forEach(el => {
          let a = that.CheckSureObj.tabs == 1 ? el.data.props.finance_category_id : that.CheckSureObj.tabs == 2 ? el.data.props.category_id : el.data.props.store_category_id
          // that.permissionInfo.info.permission_id_list.push(a)
          arrobj.push(a)
          that.flitList[1].value.push(el.data.props.category_name)
        })
      }
      if (that.CheckSureObj.tabs == 1) {
        that.query.goods_category = undefined
        that.query.store_category_id = undefined
        that.flitList[1].paramas = 'finance_category_id'
        that.query.finance_category_id = arrobj
      }
      if (that.CheckSureObj.tabs == 2) {
        that.query.finance_category_id = undefined
        that.query.store_category_id = undefined
        that.flitList[1].paramas = 'goods_category'
        that.query.goods_category = arrobj
      }
      if (that.CheckSureObj.tabs == 3) {

        that.query.finance_category_id = undefined
        that.query.goods_category = undefined
        that.flitList[1].paramas = 'store_category_id'

        that.query.store_category_id = arrobj
      }
      that.statusvisible = false;

      console.log(that.query);
      that.query.page = 1
      that.getList()
    },
    getTitleList(arr, tree) {
      let newArr = this.treeToArray(tree)
      arr.forEach((el, idx) => {
        newArr.forEach((m, n) => {
          if (m.category_id == el) {
            this.flitList[1].value.push(m.category_name)
          }
        })
      })
      return newArr
    },
    // 数据扁平化
    treeToArray(tree) {
      return tree.reduce((res, item) => {
        const { children, ...i } = item
        return res.concat(i, children && children.length ? this.treeToArray(children) : [])
      }, [])
    },
    onSelect(selectedKeys, info) {
      console.log('onSelect', info);
      this.selectedKeys = selectedKeys;
    },
    getbrandsItem(i, v) {
      //   
      if (i == 'catagory') {
        console.log(this.activeStatus_catagory);
        this.flitList[0].value = [v.text]
        let a = ''
        a = v.text == '客装' ? [1, 3] : v.text == '院装' ? [2, 4] : []
        if (v.text == '全部') {
          let arr = []
          this.filters_categorys.forEach(el => {
            if (el.text != '全部') {
              arr.push(el.text)
            }
          })
          // this.activeStatus_catagory = arr
          this.flitList[0].value = arr
          a = [1, 2, 3, 4]
        }

        this.query.goods_type = a
      }
      if (i == 'status') {
        console.log(this.activeStatus_status);
        // this.activeStatus_status = [v.text]
        this.flitList[2].value = [v.text]
        let a = ''
        a = v.text == '在售' ? 1 : 0
        if (v.text == '全部') {
          let arr = []
          this.filters_status.forEach(el => {
            if (el.text != '全部') {
              arr.push(el.text)
            }
          })
          // this.activeStatus_status = arr
          this.flitList[2].value = arr
          a = undefined
        }
        this.query.is_show = a
      }
      if (i == 'brands') {
        this.visiblePlan = false;
        // this.activeStatus_brand = [v.brand_name]
        // this.flitList[3].value = [i]
        console.log(v, this.activeStatus_brand);
        // if (v == 99999) {
        v = [v]
        let arr = []
        let arr2 = []
        let arr3 = []
        this.brandsList.forEach(el => {
          if (el.brand_id != 99999) {
            arr.push(el.brand_name)
            arr3.push(el.brand_id)
          }
          v.forEach(m => {
            if (m == el.brand_id && m != 9999) {
              arr2.push(el.brand_name)
            }
          })
        })
        if (v == 99999) {
          this.flitList[3].value = arr
        } else {
          this.flitList[3].value = arr2
        }
        // this.query.goods_brand = v.includes(99999) ? arr3 : v
      }

      this.query.page = 1
      // 发送请求 
      this.getList()
    },
    // 授权机构 搜索
    getListSearchlimits(e) {
      console.log(e);
      // 根据条件 判断是机构简称1 机构代码2 机构名称3 的搜索条件
      this.checkLimitsListfn()

    },
    checkLimitsListfn(e) {

    },
    // 授权穿梭框相关
    onlimitsChange(nextTargetKeys) {
      this.limitsModel.targetKeys = nextTargetKeys;
    },
    onlimitsChange2(nextTargetKeys) {

      console.log(this.typesModel);
      this.typesModel.targetKeys = nextTargetKeys;
    },
    onChecked1(_, e, checkedKeys, itemSelect) {
      const { eventKey } = e.node;
      itemSelect(eventKey, !isChecked(checkedKeys, eventKey));
    },
    triggerDisable(disabled) {
      this.disabled = disabled;
    },

    triggerShowSearch(showSearch) {
      this.showSearch = showSearch;
    },
    getRowSelection({ disabled, selectedKeys, itemSelectAll, itemSelect }) {
      return {
        getCheckboxProps: item => ({ props: { disabled: disabled || item.disabled } }),
        onSelectAll(selected, selectedRows) {
          const treeSelectedKeys = selectedRows
            .filter(item => !item.disabled)
            .map(({ key }) => key);
          const diffKeys = selected
            ? difference(treeSelectedKeys, selectedKeys)
            : difference(selectedKeys, treeSelectedKeys);
          itemSelectAll(diffKeys, selected);
        },
        onSelect({ key }, selected) {
          itemSelect(key, selected);
        },
        selectedRowKeys: selectedKeys,
      };
    },

    // 树形结构 转 平铺 从外到内依次递归，有 children 则继续递归
    treeToArr(data, pid = null, res = []) {
      data.forEach(item => {
        res.push({ pid: pid, id: item.id, name: item.name });
        if (item.children && item.children.length !== 0) {
          this.treeToArr(item.children, item.id, res);
        }
      });
      return res;
      // 
    },
    // 逆向 平铺-转-树形结构 ，有 children 则继续递归
    arrToTree(arr, pid = null) {
      const res = [];
      arr.forEach(item => {
        if (item.pid === pid) {
          // 这样每次都需要遍历整个数组，因此时间复杂度为 n*n
          // const children = arrToTree(arr, item.id)   

          // 往下递归时，每次数组的大小都在减小，每次都筛选掉父代元素，最终的时间复杂度为 n*logn
          const children = this.arrToTree(arr.filter(v => v.pid !== pid), item.id);
          if (children.length) {
            res.push({ ...item, children })
          } else {
            res.push({ ...item })
          }
        }
      });
      return res;
    },
    // 在售时间确认
    openTimes(record) {
      // 调取 编辑接口
      this.opentimes.visible = true
      this.opentimes.data.goods_id = record.goods_id
      // this.opentimes.goods_shou_type = 1
      this.opentimes.data.goods_shou_type = 1
      // this.permissionInfo.info.goods_shou_type='' //销售时间 1长期 （默认） 2截止
      // this.permissionInfo.info.goods_shou_end_time ='' //截止时间 戳

    },
    updataOpentime() {
      let data = this.opentimes.data
      UpdateGoods(data).then(res => {
        if (res.error_code == 0) {
          this.$message.success(res.data.message)
          this.opentimes.visible = false
          this.query.page = 1
          this.getList()
        }
      })
    },
    onChangeopentime(e, i) {
      console.log(e, i);
      this.opentimes.data.goods_shou_type = e.target.value
    },
    // 停售 在售
    stopSell(record, i) {
      let data = {
        goods_id: record.goods_id,
        is_show: i,
      }
      UpdateGoods(data).then(res => {
        if (res.error_code == 0) {
          this.$message.success(res.data.message)
          this.query.page = 1
          this.getList()
        }
      })
    },

    // 导入 导出
    importsFn() { },
    exportsFn() { },
    offset() {
      this.CheckSureObj.info = undefined
      this.CheckSureObj.checkedKeys = undefined
      this.query.finance_category_id = undefined;
      this.query.store_category_id = undefined;
      this.query.category_id = [];
      this.permission_id_list = [];
      this.permission_id_list2 = [];
      this.permission_id_list3 = [];
      this.permissionInfo.info.permission_id_list = [];
      this.expandedKeys = [];
      this.selectedKeys = [];
      this.selectedKeys2 = [];
      this.selectedKeys3 = [];
      this.permission_id_list = []
      this.permission_id_list2 = []
      this.permission_id_list3 = []
      this.query.page = 1
      this.flitList[1].value = '';
      this.query.goods_category = undefined //门店分类 不传为全部 

      this.getList()

      this.$forceUpdate();
    },
    setMonth(date, dateString) {

      this.visibleTime = false;
      this.query.start_data = dateString[0]
      this.query.end_data = dateString[1]
      this.flitList[4].value = [this.query.start_data + ' ~ ' + this.query.end_data]
      // this.query.dividend_month = dateString;
      this.getList();
    },
    changebtnsstatus(v, record) {
      // 
      console.log(v, record, '///v');
      // 根据状态判断是什么状态改变this.btnlists.btnlist
      //     { text: '编辑', value: '0', key: 0 },
      //     { text: '完成', value: '1', key: 1 },
      //     { text: '取消', value: '2', key: 2 },
      //     { text: '备注', value: '3', key: 3 },
      //     { text: '查看', value: '4', key: 4 },
      //     { text: '新增', value: '5', key: 5 },

    },
    // 点击按钮
    clickbtns(v) {

      //完成 取消 编辑 删除 备注
      if (v == 4) {//查看
        this.pageStatus = 'add'
        this.addorupdateSpecFlag = 'add'
        delete this.permissionInfo.info.goods_id
        this.$router.replace({ path: '/mall/mallorder/verificationservice', query: { type: 3 } })
        console.log(this.permissionInfo);
      }
      if (v == 0) {// 编辑
        this.pageStatus = 'add'
        this.addorupdateSpecFlag = 'add'
        delete this.permissionInfo.info.goods_id
        this.$router.replace({ path: '/mall/mallorder/verificationservice', query: { type: 1 } })
        console.log(this.permissionInfo);
      }
    },
    changeAssociation(val) { },
    makesurememo() { },
    handleChange1(e) { },
    changeGglable(e) {

    },
    changeRoute() {
      this.$router.replace({ path: this.$route.fullpath ? this.$route.fullpath : this.$route.path });
      let newobject = storage.get('seconderyMenuObj')
      Msg.$emit('getpathname', newobject)

      this.$forceUpdate();
    },
  },
  watch: {

  },

}
</script>
<style lang="less" scoped>
.personnelfiles {
  // padding: 12px;
  margin: 18px 12px 18px 18px;
  // margin: 0;
  padding: 0;
  overflow: hidden;
  // height: calc(100vh - 52px - 49px);
  // padding: 20px 0;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  height: calc(100% - 86px);
  background: #fff;
  .filter-wrap {
    background: #fff;
    border-radius: 4px 4px 0 0;
    // height: 66px;
    display: flex;
    z-index: 1;
    align-items: center;
    margin-bottom: 0px;
    padding: 0px 45px 0 22px;
    margin: 0px 0 24px 24px;
    // justify-content: space-between;
    // &::before{
    //   content: none;

    // }
    padding-right: 24px;
    .fillter_flx {
      width: 100%;
    }
  }
  /deep/ .ant-checkbox-wrapper {
    background: transparent;
    border: none;
    box-shadow: none;
  }
}
.borderbox {
  margin-left: 4px;
  display: inline-block;
  border-radius: 6px;
  text-align: center;
  width: 18px;
  height: 18px;
  // background: @btn2mianbgDColor;
  // box-shadow: 0px 2px 3px 0px @btn2mianOutDColor,
  //   inset 0px -2px 0px 0px @btn2mianInsertDColor;
  border-radius: 4px;
  // border: 1px solid @btn2mianboderDColor;
  line-height: 14px;

  // display: flex;
  // align-items: center;
  // justify-content: center;
}
.bigdropdown_imga {
  display: none;
}
.bigdropdown_img1a {
  display: none;
}
.bigdropdown {
  position: relative;
  display: inline-block;
  width: 80px;
  height: 26px;
  // background: #f2f5f5;
  border-radius: 4px;
  line-height: 26px;
  padding: 0 8px 0 8px;
  &:hover {
    background: #f2f5f5;
  }
  // .borderbox {
  //   position: absolute;
  //   top: 4px;
  //   left: 55px;
  .meiye-xiala {
    position: absolute;
    top: 2px;
    left: 59px;
    font-size: 12px;
    margin-top: -1px;
    // transform: scale(0.83);
    display: inline-block;
    // color: @btn2mianfontDColor;
    color: @fontColor4;
  }
  // &:hover {
  //   // border: 1px solid @btn2mianboderHColor;
  //   .meiye-xiala {
  //     position: absolute;
  //     top: 1px;
  //     left: 64px;
  //     font-size: 8px;
  //     margin-top: -1px;
  //     transform: scale(0.83);
  //     display: inline-block;
  //     // color: @btn2mianfontHColor;
  //     color: #414041;
  //     transform: scale(0.83) rotate(180deg);
  //   }
  // }
  // }
}
/deep/ .bgchangenode {
  background: #ccc;
}
/deep/.ant-modal-header {
  padding-left: 24px;
}
/deep/.ant-modal-footer {
  padding-bottom: 32px;
}
.btn-padding {
  // display: none;
  // background: rgba(216, 219, 236, 0.27);
  border-radius: 4px;
  color: @btn3mianFontDColor;
  // background: #f5f5fa;
  border: none !important;
  box-shadow: none !important;
  &:hover {
    color: @btn3mianFontHColor !important;
    // background-color: @btn3mianbgHColor !important;
    border: none !important;
    box-shadow: none !important;
  }
  &:focus {
    color: @btn3mianFontFColor !important;
    // background-color: @btn3mianbgFColor !important;
    border: none !important;
    box-shadow: none !important;
  }
  // border: 1px solid @btn2mianboderDColor;
  // box-shadow: 0px 2px 4px 0px @btn2mianOutDColor, inset 0px -2px 0px 0px @btn2mianInsertDColor;
  // background-color: @btn2mianbgDColor;
  // color: @btn2mianfontDColor;
  // &:active {
  //   color: @btn2mianfontFColor !important;
  //   border: 1px solid @btn2mianboderFColor !important;
  //   background-color: @btn2mianbgFColor !important;
  //   box-shadow: 0px 2px 4px 0px @btn2mianOutFColor, inset 0px -2px 0px 0px @btn2mianInsertFColor !important;
  // }

  // &:focus {
  //   color: @btn2mianfontFColor !important;
  //   border: 1px solid @btn2mianboderFColor !important;
  //   background-color: @btn2mianbgFColor;
  //   box-shadow: 0px 2px 4px 0px @btn2mianOutFColor, inset 0px -2px 0px 0px @btn2mianInsertFColor !important;
  // }

  // &:hover {
  //   color: @btn2mianfontHColor !important;
  //   border: 1px solid @btn2mianboderHColor !important;
  //   background-color: @btn2mianbgHColor;
  //   box-shadow: 0px 2px 4px 0px @btn2mianOutHColor, inset 0px -2px 0px 0px @btn2mianInsertHColor;
  // }
}
/deep/ .ant-table-header {
  // margin-top: -23px;
}
/deep/.bigdropdown .ant-dropdown-open {
  .meiye-xiala {
    position: absolute;
    top: 1px;
    left: 61px;
    font-size: 12px;
    margin-top: -1px;
    // transform: scale(0.83);
    display: inline-block;
    // color: @btn2mianfontHColor;
    color: #414041;
    transform: rotate(180deg);
  }
}
/deep/.bigdropdown3 .ant-dropdown-open {
  .meiye-xiala {
    left: 84px !important;
  }
}

/deep/.wid-102 .ant-dropdown-open {
  .meiye-xiala {
    position: absolute;
    top: 1px;
    left: 86px !important;
    font-size: 12px;
    margin-top: -1px;
    // transform: scale(0.83);
    display: inline-block;
    // color: @btn2mianfontHColor;
    color: #414041;
    transform: rotate(180deg);
  }
}

/deep/.bigdropdown .ant-popover-open {
  .meiye-xiala {
    position: absolute;
    top: 1px;
    left: 56px;
    font-size: 8px;
    margin-top: -1px;
    display: inline-block;
    // color: @btn2mianfontHColor;
    color: #414041;
    transform: rotate(180deg);
    margin-left: 3px;
  }
}
/deep/.bigdropdown3 .ant-popover-open {
  .meiye-xiala {
    position: absolute;
    top: 1px;
    left: 84px;
    font-size: 8px;
    margin-top: -1px;
    display: inline-block;
    // color: @btn2mianfontHColor;
    color: #414041;
    transform: rotate(180deg);
    margin-left: 3px;
  }
}

/deep/.left_81 .ant-popover-open {
  .meiye-xiala {
    left: 80px !important;
  }
}
/deep/
  .ant-select-dropdown-menu-item:hover:not(
    .ant-select-dropdown-menu-item-disabled
  ),
.ant-select-dropdown-menu-item-active:not(
    .ant-select-dropdown-menu-item-disabled
  ),
.ant-cascader-menu-item:hover,
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover,
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover,
.ant-tree li .ant-tree-node-content-wrapper:hover,
.ant-select-tree li .ant-select-tree-node-content-wrapper:hover {
  background-color: @selectdownbgHColor !important;
  // color: #0A955D;
  color: @selectdownfontHColor;
  font-weight: 400;
}
.smallbtnlong {
  letter-spacing: -1px !important;
}

.meiye-icon {
  color: @primaryColor;
}
/deep/ .ant-input2 .ant-input {
  padding-top: 5px;
  &::placeholder {
    padding-top: 3px;
  }
}
.linebox {
  display: flex;
  padding: 12px 0;

  .lfbox {
    width: 110px;
    font-size: 14px;
    color: @fontColor5;
    text-align: left;
    .imgxing {
      width: 8px;
      height: 22px;
      color: red;
    }
  }

  .rgbox {
    font-size: 14px;
    color: @btnColorfont1;
    // width: calc(100% - 120px);
    position: relative;
    width: 542px;

    .tuijia {
      font-size: 14px;
      color: @fontColor6;
    }
  }
}
/deep/ .ant-upload-picture-card-wrapper .ant-upload {
  width: 45px !important;
  height: 45px !important;
  padding: 0;
}
/deep/ .ant-upload-select-picture-card i {
  font-size: 14px;
}
.constmodel {
  min-height: 420px;
}
.chooseguige {
  margin-right: 120px;
  margin-left: 12px;
  &:hover {
    color: @primaryColor;
  }
}
.allsetguige {
  &:hover {
    color: @primaryColor;
  }
}
.guige_values_options {
  position: relative;
  margin-right: 20px;
  // .
  i {
    position: absolute;
    right: -8px;
    top: 0;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.13);
  }
}
.closeIcon {
  position: absolute;
  right: -8px;
  top: 0;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.13);
}
.guige_values {
  margin: 0 28px;
  flex-wrap: wrap;
  .guige_values_options {
    margin-bottom: 20px;
  }
}
.goods_specx_add {
  margin: 0 28px 12px;
}
.goods_editor {
  // width: 582px;
  width: 432px;
  height: 320px;
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid #ccc;
  overflow: hidden;
  overflow-y: auto;
}
/deep/ .myFileList .ant-spin-nested-loading {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  min-height: 40px;
}
/deep/ .guigeTable .ant-spin-nested-loading {
  min-height: 40px;
}
/deep/.ant-table-pagination.ant-pagination {
  height: 65px;
}

/deep/ .ant-table-header .ant-checkbox {
  width: 14px;
  height: 14px;
}
/deep/ .table-manage .ant-table-header .ant-checkbox-inner {
  // display: none;
}

/deep/.table-manage .ant-table-tbody .ant-checkbox-inner {
  // display: none;
}
/deep/
  .table-manage
  .ant-table-tbody
  .ant-checkbox-wrapper
  .ant-checkbox-checked
  .ant-checkbox-inner {
  display: block !important;
}
.table-manage {
  margin-top: -1px;
}
/deep/ .table-manage .ant-table-selection-column:hover .ant-checkbox-inner {
  display: block !important;
}
/deep/.tableBox .ant-table-thead .ant-table-selection-column span:nth-child(1) {
  display: inline-block;
}
/deep/.tableBox .ant-table-body .ant-table-selection-column span:nth-child(1) {
  display: none;
}
.meiye-xiala {
  color: @fontColor4;
}

// 复合搜索框
.owninputbox {
  display: inline-block;
  background: #f5f7f7;
  width: 341px !important;
  height: 36px;
  line-height: 36px;
  background: #f5f7f7;
  box-shadow: inset 0px 3px 0px 0px rgba(76, 124, 121, 0.1);
  border-radius: 4px;
  border: 1px solid #b0c3c2;
  margin-right: 20px;
  .meiye-icon {
    color: @fontColor4;
  }
  .meiye-sousuo {
    color: @fontColor5;
  }
  .owninputbox_selested {
    cursor: pointer;
    width: 103px;
    border-right: 1px solid #b0c3c2;
    display: inline-block;
    height: 22px;
    font-size: 16px;
    font-weight: 400;
    color: @fontColor4;
    line-height: 22px;
    position: relative;
    &:hover {
      .meiye-icon {
        color: @primaryColor;
      }
    }
    // padding-left: 8px;
  }
  .owninputbox_search {
    box-shadow: none;

    // i {
    //   position: absolute;
    //   right: 12px;
    //   top: 2px;
    // }
  }
  .ant-input2 {
    height: 22px;
    border: none !important;
    box-shadow: none !important;
    background: #f5f7f7;
    /deep/ .ant-input {
      height: 22px;
      border: none !important;
      box-shadow: none !important;
      background: transparent;
    }
  }
  &:hover {
    border: 1px solid @primaryColor;
    .meiye-sousuo {
      color: @primaryColor;
    }
  }
}
.datas {
  height: 22px;
  font-size: 14px;
  font-weight: 400;
  color: @fontColor5;
  line-height: 22px;
  margin-left: 12px;
}
.baseColor11 {
  color: @baseColor11;
}
.baseColor1 {
  color: red;
}
.filter {
  margin-top: -9px;
  padding-bottom: 6px;
  background: #fff;
}
.filter-wrap2 {
  background: #fff;
  height: 55px;
  padding: 18px 44px 0 22px;
  align-items: center;
  .filter_sx {
    font-size: 16px;
    font-weight: 400;
    color: @fontColor3;
    height: 16px;
    line-height: 16px;
    border-right: 1px solid #dadada;
    padding-right: 12px;
    margin-right: 18px;
  }
  .filter_type {
    height: 24px;
    background: #f3fafa;
    border-radius: 4px;
    padding: 2px 6px;
    font-size: 14px;
    font-weight: 400;
    color: @fontColor4;
    line-height: 24px;
    margin-right: 12px;
    display: flex;
    align-items: center;
    .typeitems {
      font-size: 14px;
      font-weight: 400;
      color: @baseColor11;
      display: flex;
      align-items: center;
      .overflowElip {
        display: inline-block;
        max-width: 120px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .meiye-close-bold {
        font-size: 9px;
        color: #578380;
        margin: 2px 0 0 4px;
      }
    }
  }
  .filter_clear {
    cursor: pointer;
    .meiye-routeicon-ashbin {
      color: @fontColor5;
      font-size: 10px;
    }
  }
}
.meiye-leixing,
.meiye-zhuangtai,
.meiye-fenlei,
.meiye-pinpai {
  color: rgb(158, 166, 164);
  font-size: 15px;
}

.typesbox {
  width: 358px;
  height: 368px;
  background: #ffffff;
  box-shadow: 0px 6px 8px 0px rgba(99, 161, 148, 0.08),
    0px 12px 24px 0px rgba(0, 0, 0, 0.08),
    inset 0px -3px 0px 0px rgba(205, 231, 231, 0.49);
  border-radius: 6px;
  border: 1px solid #e4e9e9;
  display: flex;
  flex-direction: column;
  .typesHead {
    margin: 12px;
  }
  .typesBody {
    flex: 1;
    overflow: auto;
    margin: 0 12px;
  }
  .typesFooter {
    width: 356px;
    height: 58px;
    line-height: 58px;
    border-top: 1px solid #e8edec;
    background: #ffffff;
    padding: 0px 12px;
    align-items: center;
    justify-content: space-between;
    /deep/ .ant-btn {
      height: 32px;
    }
  }
}
.btn3 {
  border-radius: 4px;
  color: @btn3mianFontDColor;
  background: #f5f5fa;
  border: none !important;
  box-shadow: none !important;
  &:hover {
    color: @btn3mianFontHColor !important;
    background-color: @btn3mianbgHColor !important;
    border: none !important;
    box-shadow: none !important;
  }
  &:focus {
    color: @btn3mianFontFColor !important;
    background-color: @btn3mianbgFColor !important;
    border: none !important;
    box-shadow: none !important;
  }
}
.exports_setxt {
  width: 100%;
  height: 42px;
  border-radius: 4px;
  border: 1px dashed #ccdada;
  text-align: center;
  line-height: 42px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  cursor: pointer;

  &:hover {
    border: 1px dashed @primaryColor;
    color: @baseColor11;
  }
}
.exports_setxt2_box {
  width: 100%;
  height: 237px;
  overflow-y: auto;
  background: #fafcfc;
  border-radius: 4px;
  border: 1px dashed #ccdada;
  font-size: 14px;
  font-weight: 400;
  color: @fontColor4;
  padding: 12px 16px;
}
/deep/.filter-wrap2 .filter_sx {
  color: @fontColor3 !important;
}
/deep/.typesBody .ant-tree-checkbox-disabled .ant-tree-checkbox-inner {
  display: none;
}
.plbtn {
  width: 101px;
  position: relative;
  text-align: left;
  padding-right: 30px;
  .meiye-xiala {
    margin-left: 5px;
    font-size: 12px;
    position: absolute;
    right: 15px;
    top: 9px;
  }
  &:hover {
    .meiye-xiala {
      color: @baseColor11;
      transform: rotate(180deg);
      font-size: 12px;
      position: absolute;
      right: 14px;
      top: 9px;
    }
  }
}
.ant-dropdown-open {
  // color: @baseColor11 !important;
}
.ant-dropdown-open .meiye-xiala {
  transform: rotate(180deg) !important;
  position: absolute;
  right: 14px;
  top: 9px;
}
/deep/.filter_clear {
  color: @fontColor5;
  display: inline-block;
  height: 24px;
  width: 46px;
  line-height: 24px;
  position: relative;
  text-align: right;
}
/deep/.filter_clear .meiye-shanchu {
  font-size: 14px;
  // line-height: 20px;
  position: absolute;
  left: 0;
  top: 1px;
}
/deep/.ant-popover-inner-content {
  padding: 11px 11px 11px 14px;
  //
}
/deep/.ant-calendar-picker.jzpicker {
  // margin: 0 14px 0 11px;
}
.wid-102 {
  width: 102px;
  .meiye-xiala {
    left: 84px;
  }
}
.wid-180 {
  width: 180px;
}
.mgl-16 {
  margin-left: 16px;
}
.mgr-4 {
  margin-right: 4px;
}
.font3 {
  color: @fontColor3;
}
.fontcancle {
  color: rgba(45, 56, 53, 0.6);
}
.mgl-12 {
  margin-left: 12px;
}
.shopdetails {
  margin-bottom: 16px;
  .shopdetails_img {
    width: 47px;
    height: 36px;
    margin-right: 8px;
    background: rgb(162, 200, 225);
  }
  .shopdetails_txt {
    p {
      margin: 0;
      padding: 0;
    }
    .shopdetails_names {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: @fontColor4;
    }
    .shopdetails_gg {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: @fontColor3;
    }
  }
}
.width-173 {
  width: 173px;
}
.picktxt {
  margin-bottom: 24px;
  .pickupleft {
    display: inline-block;
    width: 490px;
  }
  .pickupleftlable {
    width: 113px;
    display: inline-block;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: @fontColor3;
  }
  .pickuprgtlable {
    // width: 353px;
    display: inline-block;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: @fontColor4;
  }
}
/deep/ .popTable3 .ant-table-placeholder {
  width: auto;
}
/deep/.commonModel2 .ant-spin-nested-loading {
  min-height: 40px;
}
.mgb-16 {
  margin-bottom: 16px;
}
/deep/ .owninputbox {
  margin-right: 0px;
}
.wid-120 {
  width: 120px;
}
.wid-120 .meiye-xiala {
  left: 100px !important;
}
.elipsbox {
  display: inline-block;
  width: 27px;
  height: 27px;
  background-origin: #fff;
  border-radius: 4px;
  line-height: 27px;
  text-align: center;
  position: relative;
  cursor: pointer;
  &:hover {
    background: #f2f5f5;
  }
  .elips3 {
    display: inline-block;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background: @primaryColor;
    margin-bottom: 3px;
  }
}
.ant-dropdown-open.elipsbox {
  background: #f2f5f5;
}
.action_flex {
  display: flex;
  align-items: center;
}
.width-343 {
  width: 343px;
}
.selectrow {
  width: 100%;
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0px 2px 2px 0px rgba(99, 161, 148, 0.04),
    inset 0px -3px 0px 0px #deecec;
  border-radius: 4px;
  margin: 0 16px 6px 0;
  padding-left: 26px;
  height: 54px;
  line-height: 54px;
}
.cardsbox {
  // width: 100%;
  height: 116px;
  background: #fafcfc;
  border-radius: 8px;
  border: 1px solid #d2e6e4;
  min-height: 116px;
  display: flex;
  align-items: center;
  margin: 0 46px;
  justify-content: space-around;
  margin-bottom: 18px;
  p {
    padding: 0;
    margin: 0;
  }
  .cardsone {
    text-align: center;
    .cardsone_p1 {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: @fontColor5;
      margin-bottom: 1px;
    }
    .cardsone_p2 {
      font-size: 22px;
      font-family: DINAlternate-Bold, DINAlternate;
      font-weight: bold;
      color: @fontColor4;
    }
  }
}
.mgr-16 {
  margin-right: 16px;
}
.goodscards {
  height: 133px;
  padding: 32px 0 24px 0px;
  margin-bottom: 24px;
  margin-left: 46px;
  border-bottom: 1px solid #d6e3e0;
}
.goodscards {
  .goodscardsleft {
    width: 488px;
    .goodscardsleft_img {
      margin-right: 12px;
      img {
        width: 100px;
        height: 77px;
      }
    }
    .goodscardsleft_p1 {
      margin-bottom: 8px;
      .font4 {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: @fontColor4;
        margin-right: 6px;
      }
      .font3 {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: @fontColor3;
      }
    }
    .goodscardsleft_p2 {
      .font3 {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: @fontColor3;
      }
    }
  }
  .goodscardsrgt {
    .goodsitems {
      width: 20%;
      text-align: center;
      .goodscardsrgt_top {
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: @fontColor3;
        line-height: 20px;
        margin-bottom: 12px;
      }
      .goodscardsrgt_btm {
        height: 29px;
        font-size: 24px;
        font-family: UDC1.04-Bold, UDC104;
        font-weight: bold;
        color: @fontColor4;
        line-height: 29px;
      }
    }
    .goodsitems3 {
      width: 25%;
    }
  }
}
.popTable3 {
  margin: 0 46px;
}
.wid-248 {
  width: 248px;
}
</style>